import React from 'react'
import { string, shape, bool } from 'prop-types'
import imagePropTypes from '@/shape/image'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Surround from '@/component/Primitive/Surround'

import styles from './MediaListHeader.module.scss'

const MediaListHeader = ({ title, description, img, url, designV2 }) => {
  const isDark = useDarkContext()
  return (
    <SmartLink
      to={url}
      className={classNames(
        styles.MediaListHeader,
        isDark && styles.isDark,
        designV2 && styles.designV2
      )}
    >
      {img && (
        <Surround rounded className={styles.Image}>
          <ResponsiveMedia ratio={3 / 5}>
            <ResponsiveImage {...img} />
          </ResponsiveMedia>
        </Surround>
      )}
      {title && (
        <Type as="h2" size="title" className={styles.Title}>
          {title}
        </Type>
      )}
      {description && (
        <Type as="p" size="base" className={styles.Description}>
          {description}
        </Type>
      )}
    </SmartLink>
  )
}
MediaListHeader.propTypes = {
  title: string.isRequired,
  description: string,
  url: string.isRequired,
  img: shape(imagePropTypes),
  designV2: bool
}

export default MediaListHeader
