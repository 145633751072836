const createQuery = () => `
  ... on NewsListingV2Widget {
    id
    title
    description
    searchPlaceholder
    showFeatured
    lists
    limit
    loadMore
    loadMoreMethod
    initialPageSize
    loadMorePageSize
  }
`
export default createQuery
