import React from 'react'
import { node, number, shape, bool } from 'prop-types'
import Grid from '@/component/Primitive/Grid'
import { useThemeContext } from '@/component/Context/ThemeContext'
import filterChildComponents from '@/lib/child-component-filterer'

import BBFCColumn from './component/BBFCColumn'
import CBBFCColumn from './component/CBBFCColumn'

const Column = ({ column, children, isDark }) => {
  const theme = useThemeContext()
  const sizes = {
    1: [1, 1, 1 / 3],
    2: [1, 1, 2 / 3]
  }

  const childrenWithProps = React.Children.map(
    children,
    (child) => child && React.cloneElement(child, { columnWidth: column.width })
  )

  const childComponents = filterChildComponents(childrenWithProps)
  if (!childComponents.length) return null

  const componentMap = {
    bbfc: <BBFCColumn childrenWithProps={childComponents} isDark={isDark} />,
    cbbfc: <CBBFCColumn childrenWithProps={childComponents} isDark={isDark} />
  }

  return (
    <Grid.Item width={sizes[column.width]}>
      {column.width === 2 ? componentMap[theme] : <>{childComponents}</>}
    </Grid.Item>
  )
}

Column.propTypes = {
  column: shape({ width: number }).isRequired,
  children: node,
  isDark: bool
}

export default Column
