import PropTypes from 'prop-types'
import React from 'react'
import ArticleHeader from '@/component/Common/ArticleHeader'

const ArticleLayoutHeader = ({ article, sections }) => {
  return (
    <ArticleHeader
      article={article}
      sections={sections}
      shouldDisplayDate={article.category === 'News'}
    />
  )
}

ArticleLayoutHeader.propTypes = {
  article: PropTypes.shape({
    headline: PropTypes.string,
    contentRating: PropTypes.string,
    displayDate: PropTypes.string,
    category: PropTypes.string,
    images: PropTypes.object
  }),
  sections: PropTypes.array
}

export default ArticleLayoutHeader
