import ViewWhatsRightForYou from '@/component/Common/ViewWhatsRightForYou'
import React from 'react'
import PropTypes from 'prop-types'

const ViewWhatsRightForYouWidget = ({ title, subtitle }) => {
  return <ViewWhatsRightForYou title={title} subtitle={subtitle} />
}

ViewWhatsRightForYouWidget.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default ViewWhatsRightForYouWidget
