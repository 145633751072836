import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Icon from '@/component/Primitive/Icon'
import ContentAdvice from '@/component/Primitive/ContentAdvice/ContentAdvice'
import Prose from '@/component/Primitive/Prose'
import { Accordion } from '../Release/components/Accordion/Accordion'
import styles from './ListicleListItem.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const getImageUrlForItem = (item) => {
  if (item.images && item.images._573 && item.images._573[0]) {
    return item.images._573[0].url
  }

  if (
    item.releases &&
    item.releases[0] &&
    item.releases[0].images &&
    item.releases[0].images._573
  ) {
    return item.releases[0].images._573.url
  }

  return null
}

const ReleaseListicleListItem = ({ item }) => {
  const isDark = useDarkContext()
  const [showContentAdvice, setShowContentAdvice] = useState(false)

  const imageUrl = getImageUrlForItem(item)
  const release = item.releases && item.releases[0]
  const hasContentAdvice =
    item.contentAdvice || release.visualAdviceLayer?.overview?.length > 0

  // If there is no image, we don't want to render the item
  if (!imageUrl) {
    return null
  }

  const renderContentAdvice = () => {
    if (item.contentAdvice) {
      return (
        <Prose className={styles.ContentAdvice} html={item.contentAdvice} />
      )
    }

    if (release.visualAdviceLayer?.overview?.length > 0) {
      return (
        <ContentAdvice
          data={release.visualAdviceLayer.overview}
          rating={release.classification.toLowerCase()}
          showOnMobile
        />
      )
    }

    return null
  }

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.ItemWrap}>
        <a href={`/release/${release.slug}`} className={styles.ImageWrap}>
          <ResponsiveMedia ratio={3 / 2}>
            <ResponsiveImage src={imageUrl} alt={release.title} />
          </ResponsiveMedia>
        </a>
        <div className={styles.ItemContentWrap}>
          <a
            className={styles.TopContentWrap}
            href={`/release/${release.slug}`}
          >
            <div className={styles.TopContent}>
              <div className={styles.RatingIconWrap}>
                <Icon
                  className={styles.RatingIcon}
                  type={`rating-${release.classification.toLowerCase()}`}
                  a11yText={`rating-${release.classification.toLowerCase()}`}
                />
              </div>
              <h3 className={styles.MediaTitle}>
                {item.title || release.title}
              </h3>
            </div>
            <div
              className={classNames(
                styles[`Rating-${release.classification.toLowerCase()}`],
                styles.ShortFormInsight
              )}
            >
              {release.shortFormInsight}
            </div>
            {item.description ? (
              <Prose className={styles.SummaryWrap} html={item.description} />
            ) : (
              <div className={styles.SummaryWrap}>{release.synopsis}</div>
            )}
          </a>

          {hasContentAdvice && (
            <Accordion title="Content Advice" onChange={setShowContentAdvice} />
          )}
        </div>
      </div>
      {showContentAdvice && (
        <div className={styles.ContentAdviceWrap}>{renderContentAdvice()}</div>
      )}
    </div>
  )
}

ReleaseListicleListItem.propTypes = {
  item: PropTypes.shape({
    images: PropTypes.shape({
      _573: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string
        })
      )
    }),
    releases: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        classification: PropTypes.string,
        releaseDate: PropTypes.string,
        shortFormInsight: PropTypes.string,
        synopsis: PropTypes.string,
        slug: PropTypes.string,
        images: PropTypes.shape({
          _573: PropTypes.shape({
            url: PropTypes.string
          })
        }),
        visualAdviceLayer: PropTypes.shape({
          overview: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              strength: PropTypes.string
            })
          )
        })
      })
    ),
    title: PropTypes.string,
    description: PropTypes.string,
    contentAdvice: PropTypes.string
  })
}

export default ReleaseListicleListItem
