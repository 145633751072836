import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Html.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const HtmlWidget = (props) => {
  const { html, designV2, padding } = props
  const isDark = useDarkContext()

  return (
    <div
      className={classNames(
        'widget widget--html',
        styles.Html,
        isDark && styles.isDark,
        designV2 && 'borderRadius--V2',
        padding && 'vertical-padding--section horizontal-padding--section'
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

HtmlWidget.propTypes = {
  html: PropTypes.string,
  designV2: PropTypes.bool,
  padding: PropTypes.bool
}

export default HtmlWidget
