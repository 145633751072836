import imageProperties from '../lib/image-properties'

const createQuery = () => `
... on BioGridWidget {
  designV2
  padding
  bios {
    name
    jobTitle
    biography
    images {
      profile_1x1_300 {
        ${imageProperties}
      }
    }
  }
}
`
export default createQuery
