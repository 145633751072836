const createQuery = () => `
... on PageContentsWidget {
  id
  designV2
  padding
  items {
    heading
    link
  }
}
`
export default createQuery
