const filterChildComponents = (children) => {
  return children
    .map((childComponent) => {
      if (typeof childComponent.type !== 'function') return childComponent
      const Component = childComponent.type({
        ...childComponent.props
      })
      return Component ? childComponent : null
    })
    .filter((c) => c)
}

export default filterChildComponents
