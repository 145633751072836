import React from 'react'
import DownloadsList from '../../Common/DownloadsList'
import propTypes from 'prop-types'

const DownloadListWidget = ({ article }) => {
  const downloads = article && article.downloads
  if (!downloads || !downloads.length) return null
  return <DownloadsList title="Downloads" downloads={downloads} />
}

export default DownloadListWidget

DownloadListWidget.propTypes = {
  article: propTypes.object
}
