import PropTypes from 'prop-types'
import React from 'react'
import WidgetArea from '../../Common/WidgetArea'
import { format } from 'date-fns'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const ArticleLayoutBodyWrap = ({
  articleLayoutV2,
  publishedDate,
  children
}) => {
  const isDark = useDarkContext()
  if (articleLayoutV2) {
    return (
      <div className="horizontal-padding vertical-padding article-body-V2">
        {publishedDate && (
          <p
            className={classNames(
              'article__published-date',
              isDark && 'article__published-date--dark'
            )}
          >
            Published: {publishedDate}
          </p>
        )}
        {children}
      </div>
    )
  }
  return <>{children}</>
}

const ArticleLayoutBody = ({ article, section, instance, pageData }) => {
  const articleBodyWidgets = article && article.body && article.body.widgets
  const articleLayoutV2 = article.layout === 'articleV2'
  const publishedDate =
    article &&
    article.displayDate &&
    format(new Date(article.displayDate), 'dd MMM yyyy')

  return (
    <ArticleLayoutBodyWrap
      articleLayoutV2={articleLayoutV2}
      publishedDate={publishedDate}
    >
      <WidgetArea
        widgets={articleBodyWidgets}
        article={article}
        section={section}
        instance={instance}
        pageData={pageData}
        smallSpacing
      />
    </ArticleLayoutBodyWrap>
  )
}

ArticleLayoutBody.propTypes = {
  section: PropTypes.object,
  article: PropTypes.object,
  instance: PropTypes.object,
  pageData: PropTypes.object
}
ArticleLayoutBodyWrap.propTypes = {
  articleLayoutV2: PropTypes.bool,
  children: PropTypes.node,
  publishedDate: PropTypes.string
}
export default ArticleLayoutBody
