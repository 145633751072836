import React from 'react'
import { arrayOf, shape, string, oneOf } from 'prop-types'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import Icon from '@/component/Primitive/Icon'
import Container from '@/component/Primitive/Container'

import styles from './SocialShare.module.scss'

const types = ['facebook', 'twitter', 'reddit']

const iconMap = {
  facebook: 'facebook-square',
  twitter: 'twitter',
  reddit: 'reddit'
}

const SocialIcon = ({ link }) => (
  <div className={styles.SocialIcon}>
    <a href={link.url} target="_blank" rel="noopener noreferrer">
      <Icon
        className={styles.Icon}
        type={iconMap[link.type]}
        a11yText={link.type}
      />
    </a>
  </div>
)

const SocialShare = ({ links }) => {
  const isDark = useDarkContext()
  return (
    <div className={classnames(styles.SocialShare, isDark && styles.isDark)}>
      <Container gutter>
        <Type
          color={isDark ? 'white' : 'teal'}
          className={styles.Title}
          as="h4"
          size="display0"
        >
          Share
        </Type>
        <div className={styles.Wrapper}>
          {links &&
            links.map((link, i) => (
              <SocialIcon link={link} key={`SocialIcon-${link.type}-${i}`} />
            ))}
        </div>
      </Container>
    </div>
  )
}

SocialShare.propTypes = {
  links: arrayOf(shape({ type: oneOf(types), url: string }))
}

SocialIcon.propTypes = {
  link: shape({ type: oneOf(types), url: string })
}

export default SocialShare
