import React from 'react'
import { arrayOf, shape, string, bool } from 'prop-types'
import imagePropTypes from '@/shape/image'

import Bio from './component/Bio'
import Grid from '@/component/Primitive/Grid'

const BioGrid = ({ items, designV2 }) => (
  <Grid gutter="default">
    {items.map((bio) => (
      <Grid.Item
        trailingGap="default"
        width={[1, 1 / 2, 1 / 2, 1 / 3]}
        key={`Bio-${bio.name}`}
      >
        <Bio
          name={bio.name}
          content={bio.content}
          img={bio.img}
          title={bio.title}
          designV2={designV2}
        />
      </Grid.Item>
    ))}
  </Grid>
)

BioGrid.propTypes = {
  designV2: bool,
  items: arrayOf(
    shape({
      name: string,
      title: string,
      img: shape(imagePropTypes),
      content: string
    })
  )
}

export default BioGrid
