import React, { useState, useEffect, useCallback } from 'react'
import styles from './ScoreStep.module.scss'
import Type from '@/component/Primitive/Type'
import Icon from '@/component/Primitive/Icon'
import { string, arrayOf, func } from 'prop-types'

function getOrdinal(number) {
  let ord = 'th'

  if (number % 10 === 1 && number % 100 !== 11) {
    ord = 'st'
  } else if (number % 10 === 2 && number % 100 !== 12) {
    ord = 'nd'
  } else if (number % 10 === 3 && number % 100 !== 13) {
    ord = 'rd'
  }

  return number + ord
}

const LeaderboardModal = ({
  title,
  leaderboardData,
  userPosition,
  user,
  score,
  onClose
}) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.LeaderboardModalContent}`)) {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClose])

  return (
    <div className={styles.LeaderboardModal}>
      <button onClick={handleClose} className={styles.LeaderboardModalClose}>
        <Icon
          type="close"
          className={styles.CloseIcon}
          a11yText="Close"
          width={40}
        />
      </button>
      <div className={styles.LeaderboardModalContent}>
        <Type className={styles.LeaderboardModalHeading} size="display0">
          Leaderboard
        </Type>
        <div className={styles.Leaderboard}>
          <div className={styles.LeaderboardHeader}>
            <div className={styles.LeaderboardHeader1}>
              <Type color="white" className={styles.ScoreDetailsSubHeading}>
                Trailer Tag
              </Type>
              <Type
                bold
                size="base-large"
                color="white"
                className={styles.ScoreDetailsHeading}
              >
                {title}
              </Type>
            </div>
            <div className={styles.LeaderboardHeader2}>
              <Icon type="trophy" width={52} height={52} a11yText="trophy" />
              <Type size="display1" color="white">
                Leaderboard
              </Type>
            </div>
          </div>
          <div className={styles.LeaderboardTable}>
            <div className={styles.LeaderboardTableHeading}>
              <Type size="small" color="white">
                POS
              </Type>
              <Type size="small" color="white">
                Name
              </Type>
              <Type size="small" color="white" className={styles.HeadingPoint}>
                Points
              </Type>
            </div>
            <div className={styles.LeaderboardTableBody}>
              {leaderboardData &&
                leaderboardData.map((item, index) => (
                  <div key={index} className={styles.LeaderboardTableBodyRow}>
                    <Type
                      size="title-small"
                      color="white"
                      className={styles.BodyPOS}
                    >
                      {index + 1}
                    </Type>
                    <Type
                      size="title-small"
                      color="white"
                      className={styles.BodyName}
                    >
                      {item.name}
                    </Type>
                    <Type
                      size="title-large"
                      color="white"
                      className={styles.BodyPoint}
                    >
                      {item.score}
                    </Type>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.LeaderboardPlayerScore}>
            <div className={styles.Tilde}>~</div>
            <div className={styles.LeaderboardTableBodyRow}>
              <Type size="title-small" color="white" className={styles.BodyPOS}>
                {getOrdinal(userPosition)}
              </Type>
              <div className={styles.BodyNameWrap}>
                <Type
                  size="title-small"
                  color="white"
                  className={styles.BodyName}
                >
                  {user.name}
                </Type>
                <Icon type="trophy-2" width={30} a11yText="trophy" />
              </div>
              <Type
                size="title-large"
                color="white"
                className={styles.BodyPoint}
              >
                {score.totalScore}
              </Type>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LeaderboardModal.propTypes = {
  title: string,
  leaderboardData: arrayOf(string),
  userPosition: string,
  user: string,
  score: string,
  onClose: () => {}
}

const Leaderboard = ({
  title,
  leaderboardData,
  userPosition,
  user,
  score,
  playAgainHandle
}) => {
  const [showModal, setShowModal] = useState(false)

  const toggle = () => {
    setShowModal(!showModal)
  }

  return (
    <div className={styles.LeaderboardContainer}>
      <div className={styles.LeaderboardActionButtons}>
        <button className={styles.Button} onClick={toggle}>
          <Type color="white" size="base">
            View the Leaderboard
          </Type>
        </button>
        <button className={styles.Button} onClick={playAgainHandle}>
          Try again!
        </button>
      </div>

      {showModal && (
        <LeaderboardModal
          title={title}
          leaderboardData={leaderboardData}
          userPosition={userPosition}
          user={user}
          score={score}
          onClose={toggle}
        />
      )}
    </div>
  )
}

export default Leaderboard

Leaderboard.propTypes = {
  title: string,
  leaderboardData: arrayOf(string),
  userPosition: string,
  user: string,
  score: string,
  playAgainHandle: func
}
