import React from 'react'
import { string } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import MailchimpHead from './lib/mailchimp'
import TextControl from '@/component/Primitive/TextControl'
import CustomCheckControl from '@/component/Primitive/CheckControl/CustomCheckControl'
import Prose from '@/component/Primitive/Prose'
import ButtonStandard from '@/component/Primitive/ButtonStandard'

const Mailchimp = ({ audienceId }) => {
  const isDark = useDarkContext()
  return (
    <>
      <MailchimpHead />
      <Prose>
        <div className={isDark && 'isDark'} id="mc_embed_signup">
          <form
            action={`https://bbfc.us13.list-manage.com/subscribe/post?u=258a1360375281d87f1e89a0c&amp;id=${audienceId}`}
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <h2>Subscribe</h2>
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <TextControl
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name </label>
                <TextControl type="text" name="FNAME" id="mce-FNAME" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name </label>
                <TextControl type="text" name="LNAME" id="mce-LNAME" />
              </div>
              <div
                id="mergeRow-gdpr"
                className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
              >
                <div className="content__gdpr">
                  <label htmlFor="gdpr_325">
                    Education Newsletter Marketing Permissions
                  </label>
                  <p>
                    BBFC will use the information you provide on this form to
                    provide news, updates, resources and marketing. Please let
                    us know if you would like to receive this newsletter:
                  </p>
                  <fieldset
                    className="mc_fieldset gdprRequired mc-field-group"
                    name="interestgroup_field"
                  >
                    <label className="checkbox subfield" htmlFor="gdpr_325">
                      <CustomCheckControl
                        type="checkbox"
                        id="gdpr_325"
                        name="gdpr[325]"
                        value="Y"
                        // className='check'
                      />
                      <span className="checkbox-label">
                        I would like to receive this newsletter
                      </span>{' '}
                    </label>
                  </fieldset>
                  <p>
                    You can change your mind at any time by clicking the
                    unsubscribe link in the footer of any email you receive from
                    us, or by contacting us at bmorgan@bbfc.co.uk. We will treat
                    your information with respect. For more information about
                    our privacy practices please visit our website
                    http://www.bbfc.co.uk/privacy-statement. By clicking below,
                    you agree that we may process your information in accordance
                    with these terms.
                  </p>
                </div>
                <div className="content__gdprLegal">
                  <p>
                    We use Mailchimp as our marketing platform. By clicking
                    below to subscribe, you acknowledge that your information
                    will be transferred to Mailchimp htmlFor processing.{' '}
                    <a
                      href="https://mailchimp.com/legal/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more about Mailchimp's privacy practices here.
                    </a>
                  </p>
                </div>
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                />
              </div>
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_258a1360375281d87f1e89a0c_80dbbd3d4f"
                  tabIndex="-1"
                />
              </div>
              <div className="clear">
                <ButtonStandard
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Subscribe
                </ButtonStandard>
              </div>
            </div>
          </form>
        </div>
      </Prose>
    </>
  )
}

Mailchimp.propTypes = {
  audienceId: string.isRequired
}

export default Mailchimp
