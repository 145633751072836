import React from 'react'
import { string, shape, instanceOf, bool } from 'prop-types'
import imagePropTypes from '@/shape/image'

import moment from 'moment'

import ReleasePoster from '@/component/Common/ReleasePoster'
import MediaOutline from '@/component/Common/MediaOutline'
import Type from '@/component/Primitive/Type'
import SmartLink from '../../Primitive/SmartLink'
import Grid from '../../Primitive/Grid'

import styles from './FeaturedMedia.module.scss'
import classNames from 'classnames'

const FeaturedMedia = ({
  mediaTitle,
  title,
  shortFormInsight,
  releaseDate,
  summary,
  url,
  img,
  rating,
  designV2
}) => {
  return (
    <div
      className={classNames(styles.FeaturedMedia, designV2 && styles.designV2)}
    >
      <Grid gutter="default" vAlign="middle">
        <Grid.Item width={[1, 7 / 12]}>
          <div className={styles.Content}>
            <Type size="display1" color="teal" className={styles.Title}>
              {title || ''}
            </Type>
            <MediaOutline
              url={url}
              title={mediaTitle}
              shortFormInsight={shortFormInsight}
              size="huge"
              classification={rating}
              designV2={designV2}
            />
            <Type className={styles.Summary} size="base-large">
              {summary || ''}
            </Type>
            {releaseDate && (
              <Type className={styles.ReleaseDate} size="base">
                Release Date: {moment(releaseDate).format('DD/MM/YYYY')}
              </Type>
            )}
          </div>
        </Grid.Item>
        <Grid.Item width={[1, 5 / 12]}>
          <SmartLink to="/release" as={url} className={styles.MediaImage}>
            <ReleasePoster image={img} classification={rating} />
          </SmartLink>
        </Grid.Item>
      </Grid>
    </div>
  )
}

FeaturedMedia.propTypes = {
  rating: string.isRequired,
  title: string,
  mediaTitle: string.isRequired,
  shortFormInsight: string,
  releaseDate: instanceOf(Date),
  summary: string,
  url: string,
  img: shape(imagePropTypes),
  designV2: bool
}

export default FeaturedMedia
