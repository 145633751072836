import createFormSubmitter from './form-submission'

const create = ({
  Component,
  submitUrl,
  buildSchema,
  form,
  section,
  getUrl
}) => {
  const FormSubmitter = createFormSubmitter({
    Component,
    submitUrl,
    buildSchema,
    form,
    section
  })
  class PollSubmitter extends FormSubmitter {
    async getResults(message) {
      try {
        this.setState({ waiting: true })
        const res = await fetch(this.createUrl(getUrl))
        const data = await res.json()
        this.setState({
          results: data.data,
          successMessage: message
        })
        this.setState({ waiting: false })
      } catch (error) {
        this.setState({
          serverError: error
        })
        this.setState({ waiting: false })
      }
    }

    async onAfterSubmission() {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(this.formId, true)
      }
      await this.getResults(this.successMessage || 'Thank You')
    }

    onBeforeFormRender() {
      super.onBeforeFormRender()
      if (typeof window !== 'undefined') {
        this.isFormStored = window.localStorage.getItem(this.formId)
        this.componentProps.formStored = !!this.isFormStored
        if (this.isFormStored) this.getResults()
      }
    }
  }
  return PollSubmitter
}

export default create
