import React from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCSplitPanel from './component/BBFCSplitPanel'
import CBBFCSplitPanel from './component/CBBFCSplitPanel'

const SplitPanel = (props) => {
  const theme = useThemeContext()

  const componentMap = {
    bbfc: <BBFCSplitPanel {...props} />,
    cbbfc: <CBBFCSplitPanel {...props} />
  }

  return componentMap[theme]
}

export default SplitPanel
