import imageProperties from '../lib/image-properties'

const createQuery = () => `
... on QuizWidget {
  designV2
  padding
  quiz {
    _id
    title
    resultMessage
    description
    questions {
      question
      answerText
      items {
        label
        isCorrectAnswer
        images {
          main_16x9_340 {
            ${imageProperties}
          }
        }
      }
    }
  }
}
`
export default createQuery
