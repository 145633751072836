import MediaList from '@/component/Common/MediaList'
import React from 'react'
import { string, array, bool } from 'prop-types'

const ArticleList = ({ articles, title, designV2 }) => {
  if (!articles) return null
  return <MediaList items={articles} title={title} designV2={designV2} />
}

ArticleList.propTypes = {
  articles: array,
  title: string,
  designV2: bool
}

export default ArticleList
