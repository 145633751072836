import { displayRatings } from '../../../data/ratings.json'
import { string, oneOf, arrayOf, shape } from 'prop-types'
import imagePropTypes from '@/shape/image'

export default {
  url: string,
  rating: oneOf([...displayRatings]),
  title: string,
  img: shape(imagePropTypes),
  themes: arrayOf(string)
}
