const createQuery = () => `
... on PollWidget {
  designV2
  padding
  poll {
    _id
    title
    thankyouMessage
    resultsTitle
    description
    questions {
      question
      required
      inputType
      responses {
        response
      }
    }
  }
}
`
export default createQuery
