import React from 'react'
import { array, bool } from 'prop-types'
import BreadcrumbV1Widget from './BreadcrumbV1'
import BreadcrumbV2Widget from './BreadcrumbV2'

const BreadcrumbWidget = (props) => {
  return props.v2 ? (
    <BreadcrumbV2Widget {...props} />
  ) : (
    <BreadcrumbV1Widget {...props} />
  )
}

BreadcrumbWidget.propTypes = {
  sections: array,
  v2: bool
}

export default BreadcrumbWidget
