const createQuery = () => `
  query ArticleListingV2LoadMore($lists: [String], $page: Int, $limit: Int, $searchTerm: String, $filters: [LoadMoreFilters]) {
    loadMore(lists: $lists, page: $page, limit: $limit, searchTerm: $searchTerm, filters: $filters) {
      articles {
        _id
        headline
        fullUrlPath
        images {
          hero_16x9_1026 {
            url
          }
        }
      }
    }
  }
`

export default createQuery
