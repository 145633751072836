import React, { Fragment } from 'react'
import { array, object } from 'prop-types'

import Row from '../Row'
import Column from '../Column'
import WidgetArea from '../WidgetArea'
import Hide from '@/component/Primitive/Hide'
import ResponsiveSpacer from '@/component/Primitive/ResponsiveSpacer'
import filterChildComponents from '@/lib/child-component-filterer'

import { useDarkContext } from '@/component/Context/DarkContext'
import { useThemeContext } from '@/component/Context/ThemeContext'

const Layout = ({ layout, article, section, instance, pageData }) => {
  const isDark = useDarkContext()
  const theme = useThemeContext()
  const layoutV2 = article && article.layout === 'articleV2'

  const components = layout.map((row, rowIndex) => {
    return (
      <Row layoutV2={layoutV2} key={rowIndex} row={row}>
        {row.cols.map((column, columnIndex) => (
          <Column isDark={isDark} key={columnIndex} column={column}>
            {columnIndex !== row.cols.length - 1 && (
              <Hide at="tabletlandscape">
                <ResponsiveSpacer theme={theme} />
              </Hide>
            )}
            <WidgetArea
              includeSurround
              widgets={column.widgetArea.widgets}
              article={article}
              section={section}
              instance={instance}
              pageData={pageData}
              column={column}
              row={row}
            />
          </Column>
        ))}
      </Row>
    )
  })

  const componentsToRender = filterChildComponents(components)

  return (
    <>
      {componentsToRender.map((component, rowIndex) => {
        const isFullWidth =
          component.props.row.attributes &&
          component.props.row.attributes[0] === 'wide'
        const RowComponent = () => component
        return (
          <Fragment key={rowIndex}>
            {!isFullWidth && rowIndex === 0 && (
              <ResponsiveSpacer theme={theme} />
            )}
            <RowComponent />
            {rowIndex !== componentsToRender.length - 1 && (
              <ResponsiveSpacer theme={theme} />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

Layout.propTypes = {
  layout: array,
  article: object,
  section: object,
  instance: object,
  pageData: object
}

export default Layout
