const createQuery = () => `
... on UpcomingReleaseWidget {
  designV2
  release {
    title
    classification
    releaseDate
    shortFormInsight
    synopsis
    slug
    images {
      _573 {
        url
      }
    }
    visualAdviceLayer {
      overview {
        id
        title
        strength
      }
    }
  }
}
`
export default createQuery
