import React from 'react'

import Icon from '@/component/Primitive/Icon'
import SplitPanel from './SplitPanel'
import styles from '../theme/CBBFCSplitPanel.module.scss'
import { bool, object, node, string } from 'prop-types'

const CBBFCSplitPanel = ({
  img,
  withLines,
  children,
  splitStyle,
  link,
  shadow,
  rounded,
  displayDensity,
  tabletImg,
  mobileImg,
  fileType,
  fileSize
}) =>
  withLines ? (
    <div className={styles.SplitPanelWrapper}>
      {Array(3)
        .fill('')
        .map((item, i) => (
          <div
            key={i}
            className={styles.Line}
            style={{ left: `${(i + 1) * 25}%` }}
          >
            <Icon key={i} type="wavy-line" className={styles.Line} />
          </div>
        ))}
      <SplitPanel
        splitStyle={splitStyle}
        displayDensity={displayDensity}
        rounded={rounded}
        shadow={shadow}
        link={link}
        img={img}
        styles={styles}
        tabletImg={tabletImg}
        mobileImg={mobileImg}
        fileType={fileType}
        fileSize={fileSize}
      >
        {children}
      </SplitPanel>
    </div>
  ) : (
    <div className={styles.SplitPanelWrapper}>
      <div className={styles.Squiggle}>
        <Icon className={styles.SquiggleIcon} width={70} type="squiggle" />
      </div>
      <SplitPanel
        splitStyle={splitStyle}
        displayDensity={displayDensity}
        rounded={rounded}
        shadow={shadow}
        link={link}
        img={img}
        tabletImg={tabletImg}
        mobileImg={mobileImg}
        styles={styles}
        fileType={fileType}
        fileSize={fileSize}
      >
        {children}
      </SplitPanel>
    </div>
  )

CBBFCSplitPanel.propTypes = {
  withLines: bool,
  img: object,
  children: node,
  displayDensity: string,
  link: string,
  shadow: bool,
  rounded: bool,
  splitStyle: string,
  tabletImg: object,
  mobileImg: object,
  fileType: string,
  fileSize: string
}

export default CBBFCSplitPanel
