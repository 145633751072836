import React from 'react'
import { oneOfType, number, bool, func, string } from 'prop-types'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import IconButton from '@/component/Primitive/IconButton'

import styles from './CarouselNavigation.module.scss'

const CarouselNavigation = ({
  onClickNext,
  onClickPrev,
  isFirstDisabled,
  isSecondDisabled,
  currentSlide,
  totalSlides,
  bottomControls,
  showPageCount,
  className
}) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classnames(
        styles.NavigationWrapper,
        bottomControls && styles.bottomControls,
        className,
        isDark && styles.isDark
      )}
    >
      <IconButton
        onClick={onClickPrev}
        iconClassName={styles.Icon}
        className={classnames(
          styles.NavButton,
          isFirstDisabled && styles.disabled
        )}
        iconWidth={32}
        icon="arrow-left"
        a11yText="Button Previous"
      />
      <span className={styles.CurrentSlide}>
        {showPageCount && `${currentSlide} of ${totalSlides}`}
      </span>
      <IconButton
        onClick={onClickNext}
        iconClassName={styles.Icon}
        className={classnames(
          styles.NavButton,
          isSecondDisabled && styles.disabled
        )}
        iconWidth={32}
        icon="arrow-right"
        a11yText="Button Next"
      />
    </div>
  )
}
export default CarouselNavigation

CarouselNavigation.propTypes = {
  onClickNext: func.isRequired,
  onClickPrev: func.isRequired,
  isFirstDisabled: bool,
  isSecondDisabled: bool,
  currentSlide: oneOfType([number, bool]),
  totalSlides: oneOfType([number, bool]),
  bottomControls: bool,
  showPageCount: bool,
  className: string
}
