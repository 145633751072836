import React from 'react'
import PropTypes from 'prop-types'
import FormBuilder from '@/component/Common/FormBuilder'
import createFormSubmissionContainer from '../../Common/FormBuilder/form-submission'
import buildSchema from './schema'

const FormWidget = ({
  form,
  section,
  downloadButtonText,
  downloadUrl,
  designV2,
  padding
}) => {
  const FormSubmissionContainer = createFormSubmissionContainer({
    Component: (props) => (
      <FormBuilder
        downloadButtonText={downloadButtonText}
        downloadUrl={downloadUrl}
        designV2={designV2}
        padding={padding}
        {...props}
      />
    ),
    form,
    section,
    buildSchema,
    submitUrl: `/api/submit-form/`
  })
  return <FormSubmissionContainer />
}

FormWidget.propTypes = {
  section: PropTypes.object,
  form: PropTypes.array,
  downloadButtonText: PropTypes.string,
  downloadUrl: PropTypes.string,
  designV2: PropTypes.bool,
  padding: PropTypes.bool
}

export default FormWidget
