import React from 'react'
import PropTypes from 'prop-types'
import LatestReleaseCarousel from '@/component/Common/LatestReleaseCarousel'

const LatestReleaseCarouselWidget = ({ overlapHero, releases, title }) => {
  const parsedReleases = releases.map((release) => ({
    img: {
      src: release.images?._573?.url,
      alt: release.title
    },
    mediaTitle: release.title,
    rating: release.classification,
    releaseDate: release.releaseDate,
    shortFormInsight: release.shortFormInsight || '',
    summary: release.synopsis || '',
    url: `/release/${release.slug}`,
    visualAdviceOverview: release.visualAdviceLayer?.overview || []
  }))

  return (
    <LatestReleaseCarousel
      overlapHero={overlapHero}
      releases={parsedReleases}
      title={title}
    />
  )
}

LatestReleaseCarouselWidget.propTypes = {
  overlapHero: PropTypes.bool.isRequired,
  releases: PropTypes.array.isRequired,
  title: PropTypes.string
}

export default LatestReleaseCarouselWidget
