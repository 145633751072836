import React from 'react'
import propTypes from 'prop-types'
import Faq from '@/component/Common/Faq'

const FaqWidget = (props) => {
  const { faqs, title, description, designV2, padding } = props
  return (
    <>
      <Faq
        faqs={faqs}
        title={title}
        description={description}
        designV2={designV2}
        padding={padding}
      />
    </>
  )
}

FaqWidget.propTypes = {
  faqs: propTypes.array,
  title: propTypes.string,
  description: propTypes.string,
  designV2: propTypes.bool,
  padding: propTypes.bool
}

export default FaqWidget
