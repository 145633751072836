import ArticleListingV2 from '@/component/BBFC/ArticleListingV2'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadMoreFromLists from '@/lib/load-more-from-lists'
import createLoadMoreQuery from '@/query/widgets/bbfc/article-listing-v2-load-more'

const ArticleListingV2Widget = ({
  title,
  description,
  searchPlaceholder,
  lists,
  limit,
  loadMore,
  loadMoreMethod,
  initialPageSize,
  loadMorePageSize,
  tagFilters
}) => {
  const [articles, setArticles] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [lastSearchTerm, setLastSearchTerm] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])

  const fetchArticles = async (page, pageSize, searchTerm, filters) => {
    return await loadMoreFromLists(
      createLoadMoreQuery(),
      lists,
      page,
      pageSize,
      searchTerm,
      filters.map((filter) => ({ field: 'tags', values: [filter] }))
    )
  }

  const handleLoadMore = async () => {
    try {
      setLoadingMore(true)

      const pageSize = loadMore
        ? page === 1
          ? initialPageSize
          : loadMorePageSize
        : limit
      const data = await fetchArticles(
        page,
        pageSize,
        lastSearchTerm,
        selectedFilters
      )

      setArticles((prevArticles) => [...prevArticles, ...data])
      setPage((prevPage) => prevPage + 1)
      setHasMore(loadMore && data.length === pageSize)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingMore(false)
    }
  }

  const handleFilterChange = async (value) => {
    try {
      const updatedFilterValues = selectedFilters.includes(value)
        ? selectedFilters.filter((item) => item !== value)
        : [...selectedFilters, value]
      setSelectedFilters(updatedFilterValues)

      const data = await fetchArticles(
        1,
        initialPageSize,
        lastSearchTerm,
        updatedFilterValues
      )
      setArticles(data)
      setPage(2)
      setHasMore(loadMore && data.length === initialPageSize)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearchChange = async (value) => {
    try {
      const data = await fetchArticles(
        1,
        initialPageSize,
        value,
        selectedFilters
      )
      setArticles(data)
      setPage(2)
      setHasMore(loadMore && data.length === initialPageSize)
      setLastSearchTerm(value)
    } catch (error) {
      console.error(error)
    }
  }

  // Load initial articles
  useEffect(() => {
    handleLoadMore()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const filterOptions = tagFilters.map((tag) => tag.tag)

  return (
    <ArticleListingV2
      title={title}
      description={description}
      searchPlaceholder={searchPlaceholder}
      articles={articles}
      searchTerm={lastSearchTerm}
      filterOptions={filterOptions}
      selectedFilters={selectedFilters}
      loadMore={hasMore && loadMore}
      loadMoreMethod={loadMoreMethod}
      loadingMore={loadingMore}
      onLoadMore={handleLoadMore}
      onSearchChange={handleSearchChange}
      onFilterChange={handleFilterChange}
    />
  )
}

ArticleListingV2Widget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  lists: PropTypes.array,
  limit: PropTypes.number,
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  initialPageSize: PropTypes.number,
  loadMorePageSize: PropTypes.number,
  tagFilters: PropTypes.arrayOf(PropTypes.shape({ tag: PropTypes.string }))
}

export default ArticleListingV2Widget
