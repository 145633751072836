import moment from 'moment'

const validProperties = ['genre', 'directors', 'releaseDate']

const getYearFromDate = (date) => {
  const formattedDate = moment(date)
  const validDate = formattedDate.isValid()
  return validDate ? formattedDate.year() : ''
}
const convertArrayToString = (array) => (array && array.join(', ')) || ''

const formatters = {
  genre: convertArrayToString,
  directors: convertArrayToString,
  releaseDate: getYearFromDate
}

const propsToCategory = {
  directors: 'Director(s)',
  genre: 'Genre(s)',
  releaseDate: 'Year'
}

const createReleaseDetails = (release) =>
  validProperties.map((p) => ({
    category: propsToCategory[p],
    value: formatters[p](release[p])
  }))

module.exports = createReleaseDetails
