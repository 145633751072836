import React from 'react'
import { string, arrayOf } from 'prop-types'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import { animated, useSpring } from 'react-spring/web.cjs'

import Type from '@/component/Primitive/Type'
import Classification from './Classification'
import Prose from '@/component/Primitive/Prose'

import styles from './Answer.module.scss'

const Answer = ({
  explanation,
  correctThemes,
  correctRating,
  chosenThemes,
  chosenRating
}) => {
  const isDark = useDarkContext()
  const transition = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  })

  return (
    <animated.div
      style={transition}
      className={classNames(styles.Answer, isDark && styles.isDark)}
    >
      <Type size="base-large" as="p" className={styles.Feedback}>
        You have rated the trailer. Compare your results against one of our
        compliance officers.
      </Type>
      <div className={styles.AnswerWrapper}>
        <Classification rating={chosenRating} issues={chosenThemes} />
        <Classification rating={correctRating} issues={correctThemes} isOur />
      </div>
      <Type size="display1" className={styles.Title}>
        Why we classified it {correctRating}
      </Type>
      <Prose className={styles.Explanation} html={explanation} />
    </animated.div>
  )
}

Answer.propTypes = {
  explanation: string,
  correctThemes: arrayOf(string),
  correctRating: string,
  chosenThemes: arrayOf(string),
  chosenRating: string
}

export default Answer
