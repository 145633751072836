import React from 'react'
import classNames from 'classnames'
import { string, number, arrayOf, shape, object, bool } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import CaptionedMedia from '@/component/Primitive/CaptionedMedia'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import VimeoEmbed from '@/component/Primitive/VimeoEmbed'
import SoundcloudEmbed from '@/component/Primitive/SoundcloudEmbed'

import styles from './SubGroup.module.scss'
import Prose from '@/component/Primitive/Prose'

const Image = ({ cropRatio, images }) => {
  const imageKey = cropRatio === 0.5626 ? 'main_16x9_1026' : 'main_2x3_500'
  const imageData = images[imageKey][0]
  const image = {
    alt: (imageData && imageData.alt) || '',
    sizes: ['(min-width: 960px) 1026px', '500px'],
    srcSet: [
      {
        width: 960,
        src: images.main_16x9_1026[0].url
      },
      {
        width: 550,
        src: images.main_2x3_500[0].url
      }
    ],
    src: (imageData && imageData.url) || '',
    caption: (imageData && imageData.caption) || ''
  }
  return (
    <CaptionedMedia caption={image.caption}>
      <ResponsiveMedia ratio={cropRatio}>
        <ResponsiveImage {...image} />
      </ResponsiveMedia>
    </CaptionedMedia>
  )
}

Image.propTypes = {
  cropRatio: number,
  images: object
}

const SubGroup = ({ title, milestones, designV2 }) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classNames(
        styles.SubGroup,
        isDark && styles.isDark,
        designV2 && styles.designV2
      )}
    >
      <div className={styles.HorizontalLine} />
      <Type size="display1" className={styles.SubGroupTitle} color="teal">
        {title}
      </Type>
      {milestones.map((milestone, i) => {
        const {
          title,
          description,
          colour,
          images,
          imagePosition,
          callToActionLink,
          callToActionLabel,
          videoId,
          audioUrl
        } = milestone
        const hasImage = images && images.main_16x9_1026
        const imageRatio = imagePosition !== 'fullWidth' ? 1.5 : 0.5626
        return (
          <div className={styles.Milestone} key={`Milestone-${title}-${i}`}>
            {imagePosition !== 'fullWidth' && hasImage && (
              <div
                className={classNames(
                  styles.FloatingImage,
                  styles[`float-${imagePosition}`]
                )}
              >
                <Image images={images} cropRatio={imageRatio} />
              </div>
            )}
            <Prose className={styles.BodyWrapper} inverse={isDark}>
              <p>
                {/* <strong> */}
                <Type as="span" bold size="base-large" color={colour}>
                  {title}
                </Type>
                {/* </strong> */}
                <br />
                {description}
              </p>
            </Prose>

            {imagePosition === 'fullWidth' && hasImage && (
              <div className={styles.FullWidthImage}>
                <Image images={images} cropRatio={imageRatio} />
              </div>
            )}
            {!hasImage && videoId && (
              <ResponsiveMedia className={styles.Video} ratio={9 / 16}>
                <VimeoEmbed videoId={videoId} />
              </ResponsiveMedia>
            )}
            {audioUrl && (
              <div className={styles.Audio}>
                <SoundcloudEmbed url={audioUrl} />
              </div>
            )}
            {callToActionLabel && callToActionLink && (
              <SmartLink className={styles.CallToAction} to={callToActionLink}>
                <Type size="base-large" bold>
                  {callToActionLabel}
                </Type>
              </SmartLink>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SubGroup

SubGroup.propTypes = {
  title: string,
  designV2: bool,
  milestones: arrayOf(
    shape({
      title: string,
      colour: string,
      description: string,
      image: object,
      video: shape({
        start: string,
        videoId: string
      }),
      audio: shape({
        url: string,
        visual: bool
      }),
      imagePosition: string,
      callToActionLabel: string,
      callToActionLink: string
    })
  )
}
