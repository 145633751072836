import React from 'react'
import PropTypes from 'prop-types'
import SocialShare from '@/component/Common/SocialShare'

const Share = ({ pageData }) => {
  const uri = encodeURI(pageData && pageData.url)
  const redditUrl = `http://www.reddit.com/submit?url=${uri}`
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${uri}`
  const twitterUrl = `http://www.twitter.com/share?url=${uri}`
  const links = [
    {
      type: 'facebook',
      url: facebookUrl
    },
    { type: 'twitter', url: twitterUrl },
    { type: 'reddit', url: redditUrl }
  ]

  return (
    <>
      <SocialShare links={links} />
    </>
  )
}

Share.propTypes = {
  pageData: PropTypes.object
}

export default Share
