import React from 'react'
import propTypes from 'prop-types'
import MediaGrid from '@/component/Common/MediaGrid'

const ReleaseTripletWidget = ({ title, releases, designV2 }) => {
  if (!releases || !releases.length) return null

  const media = releases.map((release) => ({
    rating: release.classification,
    title: release.title,
    shortFormInsight: release.shortFormInsight,
    img: {
      src: release.images && release.images._451 && release.images._451.url,
      alt: release.title,
      srcSet: []
    },
    url: `/release/${release.slug}`
  }))
  return <MediaGrid media={media} title={title} designV2={designV2} />
}

ReleaseTripletWidget.propTypes = {
  title: propTypes.string,
  releases: propTypes.object,
  designV2: propTypes.bool
}

export default ReleaseTripletWidget
