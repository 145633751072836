import React from 'react'
import { string, node, oneOfType, arrayOf, number, bool } from 'prop-types'
import classnames from 'classnames'
import { DarkContextConsumer } from '@/component/Context/DarkContext'

import Flickity from 'react-flickity-component'
import Type from '@/component/Primitive/Type'
import CarouselNavigation from './component/CarouselNavigation'

import styles from './Carousel.module.scss'
import './Flickity.module.scss'

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0
    }

    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)

    this.flickityOptions = {
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
      groupCells: this.props.groupCells
    }
  }

  handleNext() {
    this.flkty.next()
  }

  handlePrev() {
    this.flkty.previous()
  }

  handleUpdate() {
    const currentSlide = this.flkty.selectedIndex + 1
    this.setState({ currentSlide })
  }

  componentDidMount() {
    this.flkty.on('ready', this.handleUpdate)
    this.flkty.on('change', this.handleUpdate)
  }

  render() {
    const {
      children,
      title,
      bottomControls,
      showPageCount,
      titleType,
      hideMobileControls,
      className,
      designV2
    } = this.props
    const totalSlides = this.flkty && this.flkty.slides.length

    return (
      <DarkContextConsumer>
        {(isDark) => (
          <div
            className={classnames(
              styles.Carousel,
              isDark && styles.isDark,
              hideMobileControls && styles.hideMobileControls,

              className
            )}
          >
            <CarouselNavigation
              onClickNext={this.handleNext}
              onClickPrev={this.handlePrev}
              isFirstDisabled={this.state.currentSlide === 1}
              isSecondDisabled={this.state.currentSlide === totalSlides}
              currentSlide={this.state.currentSlide}
              totalSlides={totalSlides}
              bottomControls={bottomControls}
              showPageCount={showPageCount}
              className={styles.Controls}
            />
            {title && (
              <div className={styles.TitleWrapper}>
                <Type
                  as="h2"
                  size={titleType}
                  className={classnames(
                    styles.Title,
                    designV2 && 'article-widget-heading'
                  )}
                  color={titleType === 'display1' && 'teal'}
                  bold
                >
                  {title}
                </Type>
              </div>
            )}
            <Flickity
              static
              flickityRef={(c) => (this.flkty = c)}
              options={this.flickityOptions}
              className={classnames(styles.Flickity, designV2 && 'designV2')}
            >
              {children}
            </Flickity>
          </div>
        )}
      </DarkContextConsumer>
    )
  }
}

Carousel.defaultProps = {
  groupCells: 1,
  bottomControls: false,
  showPageCount: false,
  hideMobileControls: false
}

Carousel.propTypes = {
  title: string,
  groupCells: number,
  children: oneOfType([arrayOf(node), node]).isRequired,
  bottomControls: bool,
  showPageCount: bool,
  titleType: string,
  hideMobileControls: bool,
  designV2: bool,
  className: string
}

export default Carousel
