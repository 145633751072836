import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'

import Type from '@/component/Primitive/Type'

import styles from './AnswerBox.module.scss'

const AnswerBox = ({ answer, answerText, isDark }) => (
  <div
    className={classNames(isDark ? styles.isDark : undefined, styles.AnswerBox)}
  >
    <Type as="p" bold className={styles.Answer}>
      <span className={styles.AnswerIntro}>Answer: </span>
      {`${answer}`}
    </Type>
    {answerText && (
      <Type as="p" className={styles.AnswerText}>
        {answerText}
      </Type>
    )}
  </div>
)

AnswerBox.propTypes = {
  answer: propTypes.string.isRequired,
  answerText: propTypes.string,
  isDark: propTypes.bool
}

export default AnswerBox
