import PropTypes from 'prop-types'
import React from 'react'
import SoundcloudEmbed from '@/component/Primitive/SoundcloudEmbed'
const AudioWidget = (props) => {
  const { url, title, designV2, padding } = props
  return (
    <>
      <SoundcloudEmbed
        designV2={designV2}
        url={url}
        hideRelated={false}
        title={title}
        padding={padding}
      />
    </>
  )
}

AudioWidget.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  designV2: PropTypes.bool,
  padding: PropTypes.bool
}

export default AudioWidget
