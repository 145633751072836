import React from 'react'
import PropTypes from 'prop-types'

import InlineImage from '@/component/Common/InlineImage'
import InlineImageCarousel from '@/component/Common/InlineImageCarousel'
import Floater from '../../Primitive/Floater'
import classNames from 'classnames'

const InlineImageWidgetWrap = ({ designV2, padding, children }) => {
  if (designV2 || padding) {
    return (
      <div
        className={classNames(
          designV2 && 'borderRadius--V2',
          padding && 'horizontal-padding--section vertical-padding--section'
        )}
      >
        {children}
      </div>
    )
  }
  return <>{children}</>
}

const InlineImageWidget = ({
  inlineImageImages,
  designV2,
  padding,
  ...props
}) => {
  if (!inlineImageImages || !inlineImageImages.length) return null
  if (inlineImageImages.length === 1) {
    const firstImageCrop =
      inlineImageImages && inlineImageImages[0] && inlineImageImages[0].crop
    const Wrapper = firstImageCrop === '16:9' ? React.Fragment : Floater
    return (
      <InlineImageWidgetWrap designV2={designV2} padding={padding}>
        <Wrapper size="small" align="left">
          <InlineImage images={inlineImageImages} {...props} />
        </Wrapper>
      </InlineImageWidgetWrap>
    )
  }
  if (inlineImageImages.length > 1) {
    return (
      <InlineImageWidgetWrap designV2={designV2} padding={padding}>
        <InlineImageCarousel images={inlineImageImages} />
      </InlineImageWidgetWrap>
    )
  }
}
InlineImageWidgetWrap.propTypes = {
  designV2: PropTypes.bool,
  padding: PropTypes.bool,
  children: PropTypes.node
}
InlineImageWidget.propTypes = {
  designV2: PropTypes.bool,
  padding: PropTypes.bool,
  inlineImageImages: PropTypes.array
}

export default InlineImageWidget
