import PropTypes from 'prop-types'
import React from 'react'
import { useDarkContext } from '@/component/Context/DarkContext'

import Prose from '@/component/Primitive/Prose'

const Standfirst = ({ article }) => {
  const isDark = useDarkContext()
  const standfirst = article && article.standfirst
  const articleLayoutV2 = article.layout === 'articleV2'

  if (!standfirst) return null
  return (
    <>
      <Prose
        className={articleLayoutV2 ? 'standfirst-V2' : ''}
        inverse={isDark}
      >
        <p>{standfirst}</p>
      </Prose>
    </>
  )
}

Standfirst.propTypes = {
  article: PropTypes.shape({
    standfirst: PropTypes.string,
    layout: PropTypes.string
  })
}

export default Standfirst
