const createQuery = () => `
... on BreadcrumbWidget {
  sections {
    name
    fullUrlPath
  }
  v2
}
`
export default createQuery
