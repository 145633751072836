import React from 'react'
import PropTypes from 'prop-types'
import Container from '@/component/Primitive/Container'
import SplitPanel from '@/component/Primitive/SplitPanel'
import Type from '@/component/Primitive/Type'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const FeaturedArticle = (props) => {
  const isDark = useDarkContext()
  const { title, articles, slanted, designV2 } = props
  if (!articles || !articles.length) return null

  const firstArticle = articles[0]
  const image =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_1000 &&
    firstArticle.images.hero_5x4_1000[0]
  const tabletImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_500 &&
    firstArticle.images.hero_5x4_500[0]
  const mobileImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_16x9_550 &&
    firstArticle.images.hero_16x9_550[0]
  const url = image && image.url
  const tabletUrl = tabletImage && tabletImage.url
  const mobileUrl = mobileImage && mobileImage.url
  const fullUrlPath = firstArticle && firstArticle.fullUrlPath

  return firstArticle ? (
    <>
      <Container center size="full">
        <SplitPanel
          splitStyle={slanted ? 'slanted' : ''}
          img={{ src: url }}
          tabletImg={{ src: tabletUrl }}
          mobileImg={{ src: mobileUrl }}
          link={fullUrlPath}
          shadow
          rounded
          displayDensity="comfortable"
          designV2={designV2}
        >
          <Type
            color={isDark ? 'white' : 'teal'}
            size="display1"
            className={classNames(designV2 && 'article-widget-heading')}
          >
            {title}
          </Type>
          <Type
            as="h2"
            size="title"
            className={classNames(designV2 && 'article-widget-heading')}
          >
            {firstArticle.headline}
          </Type>
          <Type as="p" size="base-large" className={designV2 && 'body'}>
            {firstArticle.sell}
          </Type>
        </SplitPanel>
      </Container>
    </>
  ) : null
}

FeaturedArticle.propTypes = {
  articles: PropTypes.array,
  title: PropTypes.string,
  slanted: PropTypes.bool,
  designV2: PropTypes.bool
}

export default FeaturedArticle
