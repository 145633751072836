import React from 'react'
import { string } from 'prop-types'

export const Circle = ({ className }) => (
  <svg className={className} viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="50" />
  </svg>
)

Circle.propTypes = {
  className: string
}
