import React from 'react'
import { arrayOf, string, shape } from 'prop-types'

import SubNav from '@/component/Common/SubNavigation'

const SubNavigation = (props) => {
  const { title, navItems } = props
  if (!navItems || !navItems.length) return null

  const links = navItems.map((navItem) => ({
    ...navItem,
    to: '/section',
    as: navItem.link
  }))
  return (
    <>
      <SubNav hasPointer title={title} links={links} isOrange />
    </>
  )
}

SubNavigation.propTypes = {
  title: string,
  navItems: arrayOf(shape({ name: string, link: string, rating: string }))
}

export default SubNavigation
