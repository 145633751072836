import React from 'react'
import PropTypes from 'prop-types'

import Container from '@/component/Primitive/Container'

import FilmInformationList from '@/component/Common/FilmInformationList'
import createReleaseDetails from './lib/create-release-details'

import styles from './FilmInformation.module.scss'

const FilmInformation = ({ article }) => {
  const releases = article && article.releases
  if (!releases || releases.length === 0) return null
  const release = releases && releases[0]
  const releaseDetails = createReleaseDetails(release)
  return (
    <>
      <div className={styles.FilmInformation}>
        <Container gutter>
          <FilmInformationList releaseDetails={releaseDetails} />
        </Container>
      </div>
    </>
  )
}

FilmInformation.propTypes = {
  article: PropTypes.object
}

export default FilmInformation
