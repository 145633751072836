const createQuery = () => `
  ... on NewsWidget {
    id
    title
    ctaLabel
    ctaLink
    showFeatured
    lists
    loadMore
    loadMoreMethod
    initialPageSize
    limit
    loadMorePageSize
  }
`
export default createQuery
