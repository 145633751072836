import createSubmitter from './submission'

const create = ({ Component, submitUrl, buildSchema, form, section }) => {
  const Submitter = createSubmitter({
    Component,
    submitUrl,
    buildSchema
  })
  class FormSubmitter extends Submitter {
    constructor(props) {
      super(props)
      this.formObj = form && form[0]
      this.formId = this.formObj && this.formObj._id
      this.questions = this.formObj && this.formObj.questions
      this.successMessage = this.formObj && this.formObj.thankyouMessage

      this.componentProps.form = this.formObj
      this.componentProps.section = section

      this.schema = buildSchema(this.questions)
      this.ref = null
    }

    createUrl(url) {
      return url + this.formId
    }

    onBeforeFormRender() {
      super.onBeforeFormRender()
      this.setInitialCheckBoxValues(this.questions)
    }

    setInitialCheckBoxValues(questions) {
      questions.forEach((question, i) => {
        const questionNumber = i + 1
        if (question.inputType === 'checkbox') {
          this.state.data[`question-${questionNumber}`] = {}
          question.responses.forEach((response) => {
            this.state.data[`question-${questionNumber}`][
              response.response
            ] = false
          })
        }
      })
    }

    createBody() {
      const sectionId = section && section._id
      return { data: this.state.data, sectionId }
    }
  }

  return FormSubmitter
}

export default create
