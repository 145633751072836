const createQuery = () => `
  ... on ListicleHeroWidget {
    id
    title
    ListicleHeroImages: images {
      hero_16x9_550 {
        url
        caption
        link
      }
      hero_16x9_1000 {
        url
        caption
        link
      }
    }
  }
`
export default createQuery
