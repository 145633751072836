import React from 'react'
import { string, number } from 'prop-types'
import classNames from 'classnames'

import styles from './ProgressBar.module.scss'

const ProgressBar = ({ max, value, className }) => (
  <progress
    max={max}
    value={value}
    className={classNames(styles.ProgressBar, className)}
  />
)

ProgressBar.propTypes = {
  max: number.isRequired,
  value: number.isRequired,
  className: string
}

export default ProgressBar
