import React from 'react'
import PropTypes from 'prop-types'

import retrieveAndProcessDataForPage from '@/lib/data-for-page-processor-and-retriever'
import createRenderSectionQuery from '@/query/section/render-section'
import StandardMeta from '@/component/Meta/Standard'

import Layout from '@/component/Common/Layout'
import BackgroundShapes from '@/component/Layout/BackgroundShapes'

const SectionPage = ({ section, pageData }) => {
  if (!section) return null
  const layout =
    section &&
    section.layouts &&
    section.layouts.section &&
    section.layouts.section.layout
  return (
    <>
      <StandardMeta
        title={section.metaTitle || section.name}
        description={section.metaDescription}
        seoTitle={section.shareTitle || section.metaTitle || section.name}
        seoDescription={section.shareDescription || section.metaDescription}
        canonicalUrl={pageData.url}
      />
      <BackgroundShapes />
      <Layout layout={layout} section={section} pageData={pageData} />
    </>
  )
}

SectionPage.propTypes = {
  section: PropTypes.object,
  pageData: PropTypes.object
}

SectionPage.getInitialProps = async (context) => {
  const params = context && context.query
  const query = createRenderSectionQuery()
  const page = parseInt(params && params.page) || 1
  const data = await retrieveAndProcessDataForPage(context, query, { page })
  if (data.error) return data
  const section = data && data.response && data.response.resource
  const url = data && data.vars && data.vars.url
  const pageData = { url, params }
  return { ...data.response, section, pageData }
}

export default SectionPage
