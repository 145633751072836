import React, { useState, useEffect } from 'react'
import { Component, object } from 'prop-types'

const determineImageUrl = (width, imageConfig) => {
  const possibleWidths = Object.keys(imageConfig || {})
  let imageUrl = null
  possibleWidths.some((pWidth, index) => {
    if (
      width >= parseInt(pWidth, 10) &&
      (width < parseInt(possibleWidths[index + 1], 10) ||
        !possibleWidths[index + 1])
    ) {
      imageUrl = imageConfig[pWidth]
      return true
    }
  })
  return imageUrl
}

const ResponsiveBackgroundImage = ({ Component, imageConfig, ...other }) => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 10000
  )
  const imageUrl = determineImageUrl(windowWidth, imageConfig)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  return (
    <Component style={{ backgroundImage: `url(${imageUrl})` }} {...other} />
  )
}

ResponsiveBackgroundImage.propTypes = {
  Component: Component,
  imageConfig: object
}

export default ResponsiveBackgroundImage
