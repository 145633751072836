const createQuery = () => `
  ... on LatestReleaseCarouselWidget {
    id
    overlapHero
    title
    releases {
      title
      classification
      releaseDate
      shortFormInsight
      synopsis
      slug
      images {
        _573 {
          url
        }
      }
      visualAdviceLayer {
        overview {
          id
          title
          strength
        }
      }
    }
  }
`
export default createQuery
