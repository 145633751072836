const calculatePages = (pages, currentPage, buttonsShown) => {
  const buttonsArray = Array.from(Array(pages), (e, i) => i + 1)

  const split = Math.floor(buttonsShown / 2)
  const startOfList = buttonsShown & 1 ? split + 1 : split
  const isEndOfButtons = currentPage > pages - split
  const isStartOfButtons = currentPage - startOfList > 0
  const shouldCycleButtons = isStartOfButtons && !isEndOfButtons

  if (shouldCycleButtons) {
    return buttonsArray.slice(currentPage - startOfList, currentPage + split)
  } else if (isEndOfButtons && pages > buttonsShown) {
    return buttonsArray.slice(pages - buttonsShown)
  }
  return buttonsArray.slice(0, buttonsShown)
}

export default calculatePages
