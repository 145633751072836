import React from 'react'
import classNames from 'classnames'
import intersection from 'lodash.intersection'
import { string, arrayOf, func, array } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import styles from './Rating.module.scss'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import YouTubeEmbed from '@/component/Primitive/YouTubeEmbed'
import VimeoEmbed from '@/component/Primitive/VimeoEmbed'
import Trailer from '@/component/Common/Trailer'
import Option from './Option'
import Type from '@/component/Primitive/Type'
import ButtonStandard from '@/component/Primitive/ButtonStandard'

const ratings = ['U', 'PG', '12A', '15', '18']

const Rating = ({
  enabledClassifications,
  videoProvider,
  videoId,
  onThemeSelect,
  availableOptions,
  onRatingSelect,
  chosenThemes,
  chosenRating,
  onSubmit
}) => {
  const isDark = useDarkContext()
  const isComplete = chosenThemes.length > 0 && chosenRating

  const ratingsToDisplay =
    enabledClassifications && enabledClassifications.length
      ? intersection(enabledClassifications, ratings)
      : ratings

  return (
    <div className={classNames(styles.Rating, isDark && styles.isDark)}>
      {videoProvider === 'MovieXchange' && (
        <Trailer
          hlsStreamUrl={`https://film-cdn.moviexchange.com/api/cdn/release/${videoId}/media/TrailerVideo?width=0&height=0`}
        />
      )}
      {['YouTube', 'Vimeo'].includes(videoProvider) && (
        <ResponsiveMedia className={styles.Video} ratio={9 / 16}>
          {videoProvider === 'YouTube' && <YouTubeEmbed videoId={videoId} />}
          {videoProvider === 'Vimeo' && <VimeoEmbed videoId={videoId} />}
        </ResponsiveMedia>
      )}
      <Type size="display1" className={styles.Title}>
        Rate this trailer
      </Type>
      <Type size="base-large" bold className={styles.Helper}>
        Select any themes you spot
      </Type>
      <div className={styles.Options}>
        {availableOptions &&
          availableOptions.map((option, i) => (
            <Option
              name={option}
              handleThemeSelect={onThemeSelect}
              isChecked={
                chosenThemes.length > 0 ? chosenThemes.includes(option) : false
              }
              key={`Option-${option}-${i}`}
            />
          ))}
      </div>
      <Type size="base-large" bold className={styles.Helper}>
        Select a rating
      </Type>
      <div className={styles.RatingOptions}>
        {ratingsToDisplay &&
          ratingsToDisplay.map((option, i) => (
            <Option
              rating={option}
              handleThemeSelect={onRatingSelect}
              isChecked={chosenRating === option}
              key={`RatingOption-${option}-${i}`}
            />
          ))}
      </div>
      <ButtonStandard
        className={classNames(styles.Submit, !isComplete && styles.disabled)}
        onClick={onSubmit}
        disabled={!isComplete}
      >
        Rate it
      </ButtonStandard>
    </div>
  )
}

Rating.propTypes = {
  enabledClassifications: array,
  videoId: string,
  videoProvider: string,
  chosenThemes: arrayOf(string),
  availableOptions: arrayOf(string),
  chosenRating: string,
  onThemeSelect: func,
  onRatingSelect: func,
  onSubmit: func
}

export default Rating
