import React from 'react'
import PropTypes from 'prop-types'
import SpotTheIssues from '../../Common/SpotTheIssues'

const SpotTheIssuesWidget = ({ article }) => {
  if (
    !article.spotTheIssueVideoProvider ||
    !article.spotTheIssueVideoId ||
    !article.timestamps
  ) {
    return null
  }
  return (
    <SpotTheIssues
      id={article._id}
      title={article.headline}
      videoId={article.spotTheIssueVideoId}
      timestamps={article.timestamps}
      issues={article.issues}
      overallRating={article.contentRating}
      behindTheClassification={article.spotTheIssueBehindTheClassification}
      videoProvider={article.spotTheIssueVideoProvider}
    />
  )
}

SpotTheIssuesWidget.propTypes = {
  article: PropTypes.object
}

export default SpotTheIssuesWidget
