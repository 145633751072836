import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on TimelineWidget {
    designV2
    padding
    groups {
      title
      subGroups {
        title
        milestones {
          title
          description
          colour
          callToActionLink
          callToActionLabel
          audioUrl
          videoId
          imagePosition
          images {
            main_16x9_1026 {
              ${imageProperties}
            }
            main_2x3_500 {
              ${imageProperties}
            }
          }
        }
      }
    }
  }
`
export default createQuery
