import React from 'react'
import PropTypes from 'prop-types'
import KeyFact from '../../Common/KeyFact'
import Container from '@/component/Primitive/Container'

import styles from './LookOutFor.module.scss'

const LookOutForWidget = ({ article }) => {
  if (!article.rateATrailerDidYouKnow) return null
  return (
    <>
      <div className={styles.LookOutFor}>
        <Container gutter>
          <KeyFact
            title="Did you know?"
            text={article.rateATrailerDidYouKnow}
          />
        </Container>
      </div>
    </>
  )
}

LookOutForWidget.propTypes = {
  article: PropTypes.object
}

export default LookOutForWidget
