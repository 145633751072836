import React from 'react'
import ContactDetails from '../../Common/ContactDetails'
import { string, arrayOf, shape, bool } from 'prop-types'

const ContactsWidget = ({ title, contacts, padding, designV2 }) => {
  return (
    <ContactDetails
      title={title}
      contacts={contacts}
      padding={padding}
      designV2={designV2}
    />
  )
}

ContactsWidget.propTypes = {
  title: string,
  padding: bool,
  designV2: bool,
  contacts: arrayOf(
    shape({
      name: string.isRequired,
      telephone: string,
      email: string,
      job: string
    })
  )
}

export default ContactsWidget
