import renderResource from '../resource/render-resource'
import widgetArea from '../widget/widget-area'

import widget from '../widget/widget'
import inlineImageWidget from '../widgets/general/inline-image'
import textWidget from '../widgets/general/text'
import listWidget from '../widgets/general/list'
import featuredArticleWidget from '../widgets/general/featured-article'
import heroArticleWidget from '../widgets/general/hero-article'
import subNavigationWidget from '../widgets/general/sub-navigation'
import audioWidget from '../widgets/general/audio'
import videoWidget from '../widgets/general/video'
import relatedArticles from '../widgets/general/related-articles'
import relatedArticlesV2 from '../widgets/bbfc/related-articles-v2'
import articleGridWidget from '../widgets/general/article-grid'
import articleListWidget from '../widgets/general/article-list'
import downloadWidget from '../widgets/general/download'
import breadcrumbWidget from '../widgets/general/breadcrumb'
import faqWidget from '../widgets/general/faq'
import htmlWidget from '../widgets/general/html'
import featuredDownloadWidget from '../widgets/general/featured-download'
import contactsWidget from '../widgets/general/contacts'
import pageContentsWidget from '../widgets/general/page-contents'
import timelineWidget from '../widgets/bbfc/timeline'
import formWidget from '../widgets/bbfc/form'
import ratingsNavigationWidget from '../widgets/bbfc/ratings-navigation'
import paginatedArticleListWidget from '../widgets/general/paginated-article-list'
import mailchimpWidget from '../widgets/bbfc/mailchimp'
import logoCarouselWidget from '../widgets/bbfc/logo-carousel'
import pollWidget from '../widgets/general/poll'
import manualSubNavigationWidget from '../widgets/general/manual-sub-navigation'
import ratingsBannerWidget from '../widgets/general/ratings-banner'
import viewWhatsRightForYouWidget from '../widgets/bbfc/view-whats-right-for-you'
import quizWidget from '../widgets/general/quiz'
import recentlyRatedWidget from '../widgets/bbfc/recently-rated'
import upcomingReleaseWidget from '../widgets/bbfc/upcoming-release'
import bioGridWidget from '../widgets/general/bio-grid'
import releaseTripletWidget from '../widgets/bbfc/release-triplet'
import progressScrollerWidget from '../widgets/general/progress-scroller'
import bbfcResourcesWidget from '../widgets/bbfc/bbfc-resources'
import widgetDefaultParams from './lib/widget-default-params'
import buildDefaultParameters from './lib/build-query'
import newsWidget from '../widgets/bbfc/news'
import latestReleaseCarousel from '../widgets/bbfc/latest-release-carousel'
import homeHero from '../widgets/bbfc/home-hero'
import articleListingV2 from '../widgets/bbfc/article-listing-v2'
import newsListingV2 from '../widgets/bbfc/news-listing-v2'
import listicleHero from '../widgets/bbfc/listicle-hero'

import pageSubNav from '../widgets/bbfc/page-sub-nav'

const sectionLayoutWidgets = [
  widget(),
  htmlWidget(),
  inlineImageWidget(),
  textWidget(),
  listWidget(),
  featuredArticleWidget(),
  heroArticleWidget(),
  subNavigationWidget(),
  audioWidget(),
  videoWidget(),
  relatedArticles(),
  relatedArticlesV2(),
  articleGridWidget(),
  articleListWidget(),
  downloadWidget(),
  breadcrumbWidget(),
  faqWidget(),
  featuredDownloadWidget(),
  contactsWidget(),
  pageContentsWidget(),
  ratingsNavigationWidget(),
  paginatedArticleListWidget(),
  timelineWidget(),
  mailchimpWidget(),
  pollWidget(),
  ratingsBannerWidget(),
  formWidget(),
  quizWidget(),
  recentlyRatedWidget(),
  manualSubNavigationWidget(),
  quizWidget(),
  bioGridWidget(),
  releaseTripletWidget(),
  logoCarouselWidget(),
  upcomingReleaseWidget(),
  progressScrollerWidget(),
  viewWhatsRightForYouWidget(),
  bbfcResourcesWidget(),
  newsWidget(),
  latestReleaseCarousel(),
  homeHero(),
  articleListingV2(),
  newsListingV2(),
  listicleHero(),

  pageSubNav()
]

const createQuery = () => {
  const sectionQuery = `...on Section {
    _id
    name
    slug
    fullUrlPath
    darkModeEnabled
    groupedWidgetsLayout
    metaTitle
    metaDescription
    shareTitle
    shareDescription
    layouts {
      section {
        layout {
          attributes
          cols {
            width
            widgetArea {
              ${widgetArea(sectionLayoutWidgets)}
            }
          }
        }
      }
    }
  }`
  return renderResource(
    sectionQuery,
    buildDefaultParameters(sectionQuery, widgetDefaultParams)
  )
}

export default createQuery
