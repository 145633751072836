import React from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'
import BBFCHeroArticle from './BBFCHeroArticle'
import CBBFCHeroArticle from './CBBFCHeroArticle'

const HeroArticleWidget = (props) => {
  const theme = useThemeContext()

  const componentMap = {
    bbfc: <BBFCHeroArticle {...props} />,
    cbbfc: <CBBFCHeroArticle {...props} />
  }

  return componentMap[theme]
}

export default HeroArticleWidget
