import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on BBFCResourcesWidget {
    id
    title
    links {
      label
      link
      images {
        thumbnail_3x2_438 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
