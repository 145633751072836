import React from 'react'
import { string, arrayOf, shape, bool } from 'prop-types'

import styles from './PageContents.module.scss'
import Expander from '@/component/Primitive/Expander'
import classNames from 'classnames'

const PageContentsWidget = ({ items, designV2, padding }) => {
  if (!items || items?.length === 0) return
  return (
    <Expander
      className={styles.Contents}
      title="Contents"
      classNames={{
        icon: styles.ToggleIcon,
        title: styles.Title
      }}
      designV2={designV2}
      padding={padding}
    >
      <div
        className={classNames(
          styles.ContentsLinksContainer,
          designV2 && styles.designV2
        )}
      >
        {items.map((item, i) => (
          <a key={i} href={`#${item.link}`} className={styles.ContentsLinks}>
            {item.heading}
          </a>
        ))}
      </div>
    </Expander>
  )
}

PageContentsWidget.propTypes = {
  designV2: bool,
  padding: bool,
  items: arrayOf(
    shape({
      heading: string.isRequired,
      link: string.isRequired
    })
  )
}

export default PageContentsWidget
