import { string, shape } from 'prop-types'
import imagePropTypes from '@/shape/image'

export default {
  title: string,
  fileSize: string,
  fileType: string,
  img: shape(imagePropTypes),
  url: string,
  downloadText: string
}
