import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LoadingSpinner from '@/component/Primitive/Spinner'
import FormBuilderQuestion from './FormBuilderQuestion'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import SmartLink from '@/component/Primitive/SmartLink'
import Type from '@/component/Primitive/Type'
import {
  isError,
  showNotification,
  defaultServerErrorMessage,
  defaultValidationErrorMessage
} from './lib/form-errors'
import { useDarkContext } from '@/component/Context/DarkContext'
import { saDownloadEvent } from '@/lib/simple-analytics-events'

import styles from './FormBuilder.module.scss'

const FormBuilder = (props) => {
  const {
    form,
    successMessage,
    validationError,
    serverError,
    handleOnChange,
    handleOnSubmit,
    waiting,
    downloadUrl,
    downloadButtonText,
    padding,
    designV2
  } = props
  const { questions, title } = form || {}
  const isDark = useDarkContext()

  const handleClick = (title) => {
    saDownloadEvent(`Download: ${title}`)
  }

  return (
    <div
      className={classNames(
        styles.Form,
        padding && 'vertical-padding--section horizontal-padding--section',
        designV2 && styles.designV2,
        designV2 && 'borderRadius--V2'
      )}
    >
      <Type as="h4" size="display1" className={styles.Title}>
        {title || ''}
      </Type>
      {isError(serverError) && showNotification(defaultServerErrorMessage)}
      {isError(validationError) &&
        showNotification(defaultValidationErrorMessage)}
      {successMessage && (
        <>
          <Type color={isDark && 'white'} as="p">
            {successMessage}
          </Type>
          {downloadUrl && (
            <SmartLink
              onClick={() => handleClick(form.title)}
              href={downloadUrl}
            >
              <ButtonStandard>
                <Type size="base-large">
                  {downloadButtonText || 'Download'}
                </Type>
              </ButtonStandard>
            </SmartLink>
          )}
        </>
      )}
      {!successMessage && (
        <form
          onSubmit={handleOnSubmit}
          className={classNames(styles.Questions, isDark && styles.isDark)}
        >
          {questions &&
            questions.map((question, i) => (
              <FormBuilderQuestion
                key={`form-builder-${i}`}
                question={question}
                error={validationError}
                handleOnChange={handleOnChange}
                id={i + 1}
                designV2={designV2}
              />
            ))}
          {waiting ? (
            <LoadingSpinner />
          ) : (
            <ButtonStandard
              type="submit"
              value="Submit"
              onClick={handleOnSubmit}
              className={styles.SubmitButton}
            >
              <Type size="base-large">Submit</Type>
            </ButtonStandard>
          )}
        </form>
      )}
    </div>
  )
}

FormBuilder.propTypes = {
  handleOnSubmit: PropTypes.func,
  handleOnChange: PropTypes.func.isRequired,
  waiting: PropTypes.bool,
  successMessage: PropTypes.string,
  validationError: PropTypes.object,
  serverError: PropTypes.object,
  form: PropTypes.object,
  downloadUrl: PropTypes.string,
  downloadButtonText: PropTypes.string,
  designV2: PropTypes.bool,
  padding: PropTypes.bool
}

export default FormBuilder
