import React from 'react'
import { array } from 'prop-types'

import Container from '@/component/Primitive/Container'
import SplitPanel from '@/component/Primitive/SplitPanel'
import Type from '@/component/Primitive/Type'

const CBBFCHeroArticle = (props) => {
  const { articles } = props
  const firstArticle = articles && articles[0]
  if (!firstArticle) return null
  const firstImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_1000 &&
    firstArticle.images.hero_5x4_1000[0]
  const firstImageUrl = firstImage && firstImage.url
  const firstImageAlt = firstImage && firstImage.alt
  const firstImageTablet =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_500 &&
    firstArticle.images.hero_5x4_500[0]
  const firstImageTabletUrl = firstImageTablet && firstImageTablet.url
  const firstImageTabletAlt = firstImageTablet && firstImageTablet.alt
  const firstImageMobile =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_16x9_550 &&
    firstArticle.images.hero_16x9_550[0]
  const firstImageMobileUrl = firstImageMobile && firstImageMobile.url
  const firstImageMobileAlt = firstImageMobile && firstImageMobile.alt
  const firstLink = firstArticle && firstArticle.fullUrlPath

  return (
    <Container center size="full">
      <SplitPanel
        img={{ src: firstImageUrl, alt: firstImageAlt || '' }}
        tabletImg={{
          src: firstImageTabletUrl,
          alt: firstImageTabletAlt || ''
        }}
        mobileImg={{
          src: firstImageMobileUrl,
          alt: firstImageMobileAlt || ''
        }}
        link={firstLink}
        withLines
        shadow
        rounded
        displayDensity="comfortable"
      >
        {/* <Type color="teal" size="display1">
        {title}
      </Type> */}
        <Type as="h2" size="title">
          {firstArticle.headline}
        </Type>
        <Type as="p" size="base-large">
          {firstArticle.sell}
        </Type>
      </SplitPanel>
    </Container>
  )
}

CBBFCHeroArticle.propTypes = {
  articles: array
}

export default CBBFCHeroArticle
