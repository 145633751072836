const typeMapper = {
  string: 'String',
  number: 'Int'
}

// Identify widgets that exist within query.
const buildDefaultParameters = (query, widgetAdditionalArguments) => {
  const widgetsInQuery =
    widgetAdditionalArguments &&
    widgetAdditionalArguments.filter((widget) => {
      const widgetName = widget.name
      return query.includes(widgetName)
    })

  const buildQuery = (param, value) => {
    const type = typeof value
    const formattedValue = type === 'string' ? `"${value}"` : value
    return `$${param}: ${typeMapper[type]} = ${formattedValue}`
  }

  // Add default parameters to top level of resource query.
  const defaultParams = widgetsInQuery
    .map((widget) => {
      const widgetParam = widget.param
      const widgetDefault = widget.default

      return buildQuery(widgetParam, widgetDefault)
    })
    .join(', ')

  return (defaultParams && ', ' + defaultParams) || ''
}

export default buildDefaultParameters
