import Quote from '@/component/Common/Quote'
import React from 'react'

const QuoteWidget = (props) => {
  return (
    <div>
      <Quote {...props} />
    </div>
  )
}

export default QuoteWidget
