import React from 'react'
import { string } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import Surround from '@/component/Primitive/Surround'
import SmartLink from '@/component/Primitive/SmartLink'

import styles from './KeyFact.module.scss'

const KeyFact = ({ title, text, link, linkLabel }) => {
  const isDark = useDarkContext()
  return (
    <div className={isDark && styles.isDark}>
      <Type
        as="h3"
        size="display0"
        className={styles.Title}
        color={isDark ? 'white' : 'teal'}
      >
        {title}
      </Type>
      <Surround className={styles.Wrapper} rounded>
        <Type as="p" size="base" className={styles.Text}>
          {text}
        </Type>
        {link && (
          <SmartLink to={link} className={styles.Link}>
            <Type size="base" bold>
              {linkLabel}
            </Type>
          </SmartLink>
        )}
      </Surround>
    </div>
  )
}

KeyFact.defaultProps = {
  linkLabel: 'Read more'
}

KeyFact.propTypes = {
  title: string,
  text: string,
  link: string,
  linkLabel: string
}

export default KeyFact
