import React from 'react'
import { arrayOf, string, shape, bool } from 'prop-types'
import mediaPropTypes from '@/shape/media'
import { RatingContextProvider } from '@/component/Context/RatingContext'

import Type from '../../Primitive/Type'
import SmartLink from '../../Primitive/SmartLink'
import Grid from '../../Primitive/Grid'
import ReleasePoster from '../ReleasePoster'
import MediaOutline from '../MediaOutline'

import styles from './MediaGrid.module.scss'
import classNames from 'classnames'

const MediaGrid = ({ media, title, linkText, linkTo, designV2 }) => {
  return (
    <div className={classNames(styles.MediaGrid, designV2 && styles.designV2)}>
      <div className={styles.TitleWrapper}>
        <Type color="teal" as="h2" size="display1">
          {title}
        </Type>
        {linkText && linkTo && (
          <SmartLink to={linkTo}>
            <Type color="teal" as="p">
              {linkText}
            </Type>
          </SmartLink>
        )}
      </div>

      <Grid flex gutter="small" mobileCarousel>
        {media.map((item, i) => (
          <Grid.Item mobileCarousel key={i} width={[1 / 3]}>
            <RatingContextProvider rating={item.rating}>
              <div className={styles.MediaWrapper}>
                <div>
                  <SmartLink to="/release" as={item.url}>
                    <ReleasePoster
                      image={item.img}
                      classification={item.rating}
                      className={styles.ImageWrapper}
                      isFullHeight
                    />
                  </SmartLink>
                  <MediaOutline
                    url={item && item.url}
                    title={item && item.title}
                    size="small"
                    designV2={designV2}
                  />
                </div>
                <MediaOutline
                  fixed
                  url={item && item.url}
                  themes={item && item.themes}
                  shortFormInsight={item && item.shortFormInsight}
                  classificationDate={item && item.classificationDate}
                  size="small"
                  type={item.type}
                />
              </div>
            </RatingContextProvider>
          </Grid.Item>
        ))}
      </Grid>
    </div>
  )
}
MediaGrid.propTypes = {
  media: arrayOf(shape(mediaPropTypes)),
  title: string,
  linkTo: string,
  linkText: string,
  designV2: bool
}

export default MediaGrid
