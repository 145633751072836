import BBFCResources from '@/component/Common/BBFCResources'
import React from 'react'
import PropTypes from 'prop-types'

const BBFCResourcesWidget = ({ title, links }) => {
  // Ensure we don't render any items that don't have a thumbnail
  const items = links
    .filter((link) => link.images && link.images.thumbnail_3x2_438)
    .map((link) => ({
      imageUrl: link.images.thumbnail_3x2_438[0].url,
      label: link.label,
      link: link.link,
      imageAlt: link.images.thumbnail_3x2_438[0].alt
    }))

  return <BBFCResources title={title} items={items} />
}

BBFCResourcesWidget.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.shape({
        thumbnail_3x2_438: PropTypes.shape({
          src: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number
        })
      })
    })
  )
}

export default BBFCResourcesWidget
