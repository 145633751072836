const createQuery = () => `
... on FeaturedDownloadWidget {
  description
  title
  designV2
  padding
  downloadUrl
  downloadSize
  downloadExtension
  featuredDownloadImages: images {
    main_16x9_550 {
      url
      caption
      link
    }
    main_16x9_1000 {
      url
      caption
      link
    }
  }
}
`
export default createQuery
