const inlineImageMapper = (mapper, images) => {
  return (
    images &&
    images.map((image) => {
      const extractedImage =
        image &&
        image.images &&
        image.images[mapper[image.crop]] &&
        image.images[mapper[image.crop]][0]
      return {
        alt: (extractedImage && extractedImage.alt) || '',
        src: (extractedImage && extractedImage.url) || '',
        link: (extractedImage && extractedImage.link) || '',
        caption: (extractedImage && extractedImage.caption) || '',
        crop: image && image.crop
      }
    })
  )
}

export default inlineImageMapper
