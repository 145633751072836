import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on RelatedArticlesWidget {
    title
    subtitle

    articles {
      _id
      headline
      shortHeadline
      fullUrlPath
      sell
      contentRating
      images {
        hero_16x9_712 {
          ${imageProperties}
        }
        thumbnail_3x2_438 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
