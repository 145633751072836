import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './BBFCResources.module.scss'
import useEmblaCarousel from 'embla-carousel-react'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

const BBFCResources = ({ title, items }) => {
  const isDark = useDarkContext()
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    containScroll: 'keepSnaps',
    skipSnaps: true
  })
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])
  useEffect(() => {
    if (!emblaApi) return
    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.Content}>
        <div className={styles.TopContent}>
          <h2 className="heading2">{title}</h2>
        </div>
        <div className={styles.MainContent}>
          <div className={styles.CarouselContainer}>
            <div className={styles.CarouselContent} ref={emblaRef}>
              <div className={styles.CarouselContentWrap}>
                {items.map((item, index) => (
                  <a
                    href={item.link}
                    style={{ backgroundImage: `url(${item.imageUrl})` }}
                    key={index}
                    className={styles.CarouselItemContainer}
                  >
                    <span role="img" aria-label={item.imageAlt || item.label} />
                    <div className={styles.CarouselItemLabel}>{item.label}</div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.CarouselDots}>
            {scrollSnaps.map((_, index) => (
              <div
                className={classNames(
                  styles.CarouselDot,
                  index === selectedIndex && styles.ActiveCarouselDot
                )}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

BBFCResources.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      imageUrl: PropTypes.string
    })
  )
}

export default BBFCResources
