import React, { useCallback, useEffect, useState } from 'react'
import styles from './LatestReleaseCarousel.module.scss'
import Autoplay from 'embla-carousel-autoplay'
import CarouselItem from './Carouseltem/CarouselItem'
import useEmblaCarousel from 'embla-carousel-react'
import classNames from 'classnames'
import RightArrow from '@/asset/img/redesign24/thick-right-arrow.svg'
import PropTypes from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

const LatestReleaseCarousel = ({ overlapHero, releases, title }) => {
  const isDark = useDarkContext()
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      slidesToScroll: 1,
      containScroll: 'keepSnaps',
      skipSnaps: true,
      loop: true
    },
    [
      Autoplay({
        playOnInit: true,
        delay: 20000
      })
    ]
  )
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])
  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [
    emblaApi
  ])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [
    emblaApi
  ])
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
    emblaApi.plugins().autoplay.reset()
  }, [])
  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
      emblaApi.plugins().autoplay.reset()
    },
    [emblaApi]
  )
  useEffect(() => {
    if (!emblaApi) return
    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])
  return (
    <div
      className={classNames(
        styles.ContainerWrapper,
        overlapHero && styles.OverlapHero,
        isDark && styles.dark
      )}
    >
      <div className={styles.Container}>
        <div className={styles.Content}>
          <div className={styles.TopContent}>
            <h2 className={styles.ContentHeading}>{title}</h2>
          </div>
          <div className={styles.MainContent}>
            <div className={styles.CarouselContainer}>
              <div className={styles.CarouselContent} ref={emblaRef}>
                <div className={styles.CarouselContentWrap}>
                  {releases.map((item, index) => (
                    <CarouselItem
                      key={`latest-release-carousel--${index}`}
                      {...item}
                    />
                  ))}
                </div>
              </div>
            </div>

            {releases && releases.length > 1 && (
              <div className={styles.CarouselDots}>
                {scrollSnaps.map((_, index) => (
                  <button
                    aria-label={releases[index].mediaTitle}
                    className={classNames(
                      styles.CarouselDot,
                      index === selectedIndex && styles.ActiveCarouselDot
                    )}
                    key={index}
                    onClick={() => onDotButtonClick(index)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {releases && releases.length > 1 && (
        <div className={styles.CarouselButtons}>
          <button
            className={classNames(
              styles.CarouselButton,
              styles.PrevButton,
              prevBtnDisabled && styles.DisabledButton
            )}
            onClick={scrollPrev}
            disabled={prevBtnDisabled}
          >
            <img alt="prev" src={RightArrow} />
          </button>
          <button
            className={classNames(
              styles.CarouselButton,
              styles.NextButton,
              nextBtnDisabled && styles.DisabledButton
            )}
            onClick={scrollNext}
            disabled={nextBtnDisabled}
          >
            <img alt="next" src={RightArrow} />
          </button>
        </div>
      )}
    </div>
  )
}
LatestReleaseCarousel.propTypes = {
  overlapHero: PropTypes.bool.isRequired,
  title: PropTypes.string,
  releases: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      }),
      mediaTitle: PropTypes.string,
      rating: PropTypes.string,
      releaseDate: PropTypes.string,
      shortFormInsight: PropTypes.string,
      summary: PropTypes.string,
      url: PropTypes.string,
      visualAdviceOverview: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          strength: PropTypes.number
        })
      )
    })
  )
}
export default LatestReleaseCarousel
