const createQuery = () => `
... on ReleaseTripletWidget {
  title
  designV2
  releases {
    title
    classification
    shortFormInsight
    synopsis
    slug
    images {
      _451 {
        url
      }
    }
  }
}
`
export default createQuery
