const formatArticles = (articles, title) =>
  articles &&
  articles.map((article) => {
    const src =
      article &&
      article.images &&
      article.images.thumbnail_3x2_438 &&
      article.images.thumbnail_3x2_438[0] &&
      article.images.thumbnail_3x2_438[0].url
    const alt =
      article &&
      article.images &&
      article.images.thumbnail_3x2_438 &&
      article.images.thumbnail_3x2_438[0] &&
      article.images.thumbnail_3x2_438[0].alt

    return {
      img: { src: src, alt: alt || title },
      heading: article && article.headline,
      body: article && article.sell,
      link: article && article.fullUrlPath,
      contentRating: article && article.contentRating
    }
  })

module.exports = formatArticles
