import React from 'react'
import PropTypes from 'prop-types'

import SelectControl from '@/component/Primitive/SelectControl'

const StandardControlField = ({
  showRequired = true,
  required,
  error,
  label,
  responses,
  name,
  handleOnChange,
  ...other
}) => {
  return (
    <div>
      <SelectControl name={name} onChange={handleOnChange}>
        <option value={null}>Please select a response</option>
        {responses &&
          responses.map((response, i) => {
            return (
              <option key={i} value={response.response}>
                {response.response}
              </option>
            )
          })}
      </SelectControl>
    </div>
  )
}

StandardControlField.defaultProps = {}

StandardControlField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  responses: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default StandardControlField
