import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ArticleListingV2.module.scss'
import SearchIcon from '@/asset/img/redesign24/search--grey.svg'
import SearchIconWhite from '@/asset/img/redesign24/search--white.svg'
import ArticleThumbnailItem from './ArticleThumbnailItem/ArticleThumbnailItem'
import LoadMore from '@/component/Common/LoadMore'
import Prose from '@/component/Primitive/Prose'
import { useDarkContext } from '@/component/Context/DarkContext'

const ArticleListingV2 = ({
  title,
  description,
  searchPlaceholder,
  articles,
  searchTerm: initialSearchTerm,
  filterOptions,
  selectedFilters,
  loadMore,
  loadMoreMethod,
  loadingMore,
  onLoadMore,
  onSearchChange,
  onFilterChange
}) => {
  const isDark = useDarkContext()
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const handleFilterChanged = (e) => {
    onFilterChange(e.target.value)
  }

  const handleSearchTermChanged = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSearchTermKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchChange(searchTerm)
    }
  }

  const handleSearchButtonClicked = () => {
    onSearchChange(searchTerm)
  }

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.Content}>
        {(title || description) && (
          <div className={styles.TopContent}>
            <div className={styles.TitleWrap}>
              {title && <h1 className="heading1">{title}</h1>}
              <div className={styles.SearchWrap}>
                <input
                  className={styles.SearchInput}
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={handleSearchTermChanged}
                  onKeyDown={handleSearchTermKeyDown}
                />
                <button onClick={handleSearchButtonClicked}>
                  <img
                    src={isDark ? SearchIconWhite : SearchIcon}
                    alt="search"
                  />
                </button>
              </div>
            </div>

            {description && (
              <Prose className={styles.Description} html={description} />
            )}

            {filterOptions && (
              <div className={styles.FilterContainer}>
                <div className={classNames(styles.FiltersBar)}>
                  {filterOptions.map((option, i) => (
                    <div
                      key={`filter-option--${i}`}
                      className={styles.FilterWrap}
                    >
                      {option}
                      <div className={styles.CheckboxWrap}>
                        <input
                          type="checkbox"
                          value={option}
                          name={option}
                          className={styles.CheckboxInput}
                          checked={selectedFilters.includes(option)}
                          onChange={handleFilterChanged}
                        />
                        <span className={styles.CheckedInput} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.MainContent}>
          {articles.length > 0 ? (
            <div className={styles.List}>
              {articles.map((item, index) => (
                <ArticleThumbnailItem {...item} key={`article--${index}`} />
              ))}
            </div>
          ) : (
            <div className={styles.Description}>
              Sorry, there's no matches for your search
            </div>
          )}
        </div>

        {loadMore && (
          <LoadMore
            text="Load More"
            loadMoreMethod={loadMoreMethod}
            visible
            busy={loadingMore}
            onLoadMore={onLoadMore}
          />
        )}
      </div>
    </div>
  )
}

ArticleListingV2.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  articles: PropTypes.array,
  searchTerm: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func
}

export default ArticleListingV2
