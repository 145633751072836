import PropTypes from 'prop-types'
import React from 'react'
import WidgetArea from '../../Common/WidgetArea'

const RelatedWidgets = ({ article, section, pageData }) => {
  const articleRelatedWidgets =
    article && article.relatedWidgets && article.relatedWidgets.widgets
  if (!articleRelatedWidgets.length) return null
  return (
    <WidgetArea
      widgets={articleRelatedWidgets}
      article={article}
      section={section}
      pageData={pageData}
    />
  )
}

RelatedWidgets.propTypes = {
  section: PropTypes.object,
  article: PropTypes.object,
  pageData: PropTypes.object
}

export default RelatedWidgets
