import makeGraphqlRequest from './graphql-requestor'

const loadMoreFromLists = async (
  query,
  listIds,
  page,
  limit,
  searchTerm = '',
  filters = []
) => {
  const response = await makeGraphqlRequest(query, {
    lists: listIds,
    page,
    limit,
    searchTerm,
    filters
  })

  if (response.errors) {
    console.error('Error loading more articles:', response.errors[0].message)
    return []
  }

  return response.loadMore.articles
}

module.exports = loadMoreFromLists
