import React from 'react'
import Helmet from 'react-helmet'

const script = () =>
  "(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);"

const MailchimpHead = () => (
  <Helmet>
    <script
      type="text/javascript"
      src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
    />
    <script dangerouslySetInnerHTML={{ __html: script() }} />
  </Helmet>
)

export default MailchimpHead
