import classNames from 'classnames'
import useEmblaCarousel from 'embla-carousel-react'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './RelatedArticlesV2.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const RelatedArticlesV2 = ({ title, items, hideTitle }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    containScroll: 'keepSnaps',
    skipSnaps: true
  })
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const isDark = useDarkContext()

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])
  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) return
    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return (
    <div
      className={classNames(styles.Container, isDark && styles.DarkContainer)}
    >
      <div className={styles.Content}>
        {!hideTitle && (
          <div className={styles.TopContent}>
            <h2 className="heading2">{title}</h2>
          </div>
        )}
        <div className={styles.MainContent}>
          <div className={styles.CarouselContainer}>
            <div className={styles.CarouselContent} ref={emblaRef}>
              <div className={styles.CarouselContentWrap}>
                {items.map((item, index) => (
                  <a
                    href={item.link}
                    style={{ backgroundImage: `url(${item.img.src})` }}
                    key={index}
                    className={styles.CarouselItemContainer}
                  >
                    <span
                      role="img"
                      aria-label={item.img.alt || item.heading}
                    />
                    <div className={styles.CarouselItemLabelWrap}>
                      <div className={styles.CarouselItemLabel}>
                        {item.heading}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.CarouselDots}>
            {scrollSnaps.map((_, index) => (
              <button
                aria-label={items[index].heading}
                className={classNames(
                  styles.CarouselDot,
                  index === selectedIndex && styles.ActiveCarouselDot
                )}
                key={index}
                onClick={() => onDotButtonClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

RelatedArticlesV2.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      img: PropTypes.object,
      heading: PropTypes.string
    })
  ),
  hideTitle: PropTypes.bool
}

export default RelatedArticlesV2
