import React from 'react'
import { string, bool, node } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Prose from '@/component/Primitive/Prose'
import classNames from 'classnames'

const TextWidgetWrap = (props) => {
  const { designV2, children, padding } = props
  if (designV2) {
    return (
      <div
        className={classNames(
          'borderRadius--V2',
          padding && 'vertical-padding--section horizontal-padding--section'
        )}
      >
        {children}
      </div>
    )
  }
  return <>{children}</>
}

const TextWidget = (props) => {
  const { html, designV2, padding } = props
  const isDark = useDarkContext()

  return (
    <TextWidgetWrap designV2={designV2} padding={padding}>
      <Prose html={html} inverse={isDark} />
    </TextWidgetWrap>
  )
}

TextWidgetWrap.propTypes = {
  designV2: bool,
  children: node,
  padding: bool
}

TextWidget.propTypes = {
  html: string,
  designV2: bool,
  padding: bool
}

export default TextWidget
