const createQuery = () => `
  ... on RecentlyRatedWidget {
    designV2
    releases {
      classification
      classificationDate
      title
      mediaType
      shortFormInsight
      slug
      images {
        _451 {
          url
        }
      }
    }
  }
`
export default createQuery
