import React from 'react'
import PropTypes from 'prop-types'
import styles from './ViewWhatsRightForYou.module.scss'

import SearchFilter from '../../Primitive/SearchFilter/SearchFilter'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const ratingOptions = ['U', 'PG', '12A', '12', '15', '18']

const ViewWhatsRightForYou = ({ title, subtitle }) => {
  const isDark = useDarkContext()
  if (!ratingOptions.length) return null

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.Content}>
        <div className={styles.TopContent}>
          <h2 className="heading2">{title || 'View what’s right for you'}</h2>
          <p className="body">
            {subtitle || 'Use our quick search tool to find something to watch'}
          </p>
        </div>
        <SearchFilter shorterTypesList hideAdultContentOption />
      </div>
    </div>
  )
}

ViewWhatsRightForYou.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default ViewWhatsRightForYou
