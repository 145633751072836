import React from 'react'

import { useThemeContext } from '@/component/Context/ThemeContext'
import FilmRatingStrip from '@/component/Common/FilmRatingStrip'
import getRatings from '@/component/Common/RatingsSubNavigation/get-ratings'
import { bool } from 'prop-types'

const RatingsBanner = ({ designV2 }) => {
  const theme = useThemeContext()
  const ratings = getRatings(theme)
  return <FilmRatingStrip ratings={ratings} designV2={designV2} />
}

RatingsBanner.propTypes = {
  designV2: bool
}
export default RatingsBanner
