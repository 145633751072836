import React from 'react'
import { node, bool } from 'prop-types'
import classNames from 'classnames'

import Surround from '@/component/Primitive/Surround'
import Container from '@/component/Primitive/Container'

import styles from '../theme/BBFCColumn.module.scss'

const BBFCColumn = ({ childrenWithProps, isDark }) => {
  return (
    <Surround
      isDark={isDark}
      rounded
      solid
      shadow
      className={classNames(styles.Column, 'column')}
    >
      <Container gutter>{childrenWithProps}</Container>
    </Surround>
  )
}

BBFCColumn.propTypes = {
  childrenWithProps: node,
  isDark: bool
}

export default BBFCColumn
