import React from 'react'
import Downloads from '@/component/Common/Downloads/'

const Downloadwidget = (props) => {
  const {
    downloads,
    title,
    description,
    orientation,
    listStyle,
    designV2,
    padding
  } = props || {}
  return (
    <>
      <Downloads
        title={title}
        description={description}
        downloads={downloads}
        orientation={orientation}
        listStyle={listStyle}
        designV2={designV2}
        padding={padding}
      />
    </>
  )
}

export default Downloadwidget
