import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on HomeHeroWidget {
    id
    items {
      type
      title
      subHeading
      link
      images {
        hero_16x9_928 {
          ${imageProperties}
        }
        hero_16x9_550 {
          ${imageProperties}
        }
        hero_1440x400_1440 {
          ${imageProperties}
        }
      }
      article {
        _id
        headline
        shortHeadline
        fullUrlPath
        sell
        contentRating
        images {
          hero_16x9_928 {
            ${imageProperties}
          }
          hero_16x9_550 {
            ${imageProperties}
          }
          hero_1440x400_1440 {
            ${imageProperties}
          }
        }
      }
      titleOverride
    }
  }
`
export default createQuery
