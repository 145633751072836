import PropTypes from 'prop-types'
import React from 'react'
import RateATrailer from '../../Common/RateATrailer'

const RateATrailerWidget = ({ article, instance }) => {
  if (
    !article.rateATrailerVideoProvider ||
    !article.rateATrailerVideoId ||
    !article.rateATrailerReasoning
  ) {
    return null
  }
  return (
    <RateATrailer
      enabledClassifications={instance && instance.enabledClassifications}
      videoProvider={article.rateATrailerVideoProvider}
      availableOptions={article.rateATrailerOptions}
      videoId={article.rateATrailerVideoId}
      correctRating={article.contentRating}
      correctThemes={article.rateATrailerIssues}
      explanation={article.rateATrailerReasoning}
    />
  )
}

RateATrailerWidget.propTypes = {
  article: PropTypes.object,
  instance: PropTypes.object
}

export default RateATrailerWidget
