import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './NewsListingV2.module.scss'
import SearchIcon from '@/asset/img/redesign24/search--grey.svg'
import Prose from '@/component/Primitive/Prose'
import SearchIconWhite from '@/asset/img/redesign24/search--white.svg'

import NewsMainContent from '@/component/Common/News/components/NewsMainContent'
import LoadMore from '@/component/Common/LoadMore'
import { compareDesc, format } from 'date-fns'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const NewsListingV2 = ({
  title,
  description,
  searchPlaceholder,
  showFeatured,
  searchTerm: initialSearchTerm,
  articles,
  loadMore,
  loadMoreMethod,
  loadingMore,
  onLoadMore,
  onSearchChange
}) => {
  const isDark = useDarkContext()
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  const sortedItems = articles
    .sort((a, b) => compareDesc(a.date, b.date)) // sort by date
    .map((item) => ({
      ...item,
      date: format(item.date, 'do MMMM yyy')
    }))
  const featuredItem = showFeatured
    ? sortedItems.find((item) => item.isFeaturedNew)
    : null
  const otherItems = sortedItems.filter(
    (item) => !featuredItem || item.id !== featuredItem.id
  )

  const handleSearchTermChanged = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSearchTermKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchChange(searchTerm)
    }
  }

  const handleSearchButtonClicked = () => {
    onSearchChange(searchTerm)
  }

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.Content}>
        {(title || description) && (
          <div className={styles.TopContent}>
            <div className={styles.TitleWrap}>
              {title && <h1 className="heading1">{title}</h1>}
              <div className={styles.SearchWrap}>
                <input
                  className={styles.SearchInput}
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={handleSearchTermChanged}
                  onKeyDown={handleSearchTermKeyDown}
                />
                <button onClick={handleSearchButtonClicked}>
                  <img
                    src={isDark ? SearchIconWhite : SearchIcon}
                    alt="search"
                  />
                </button>
              </div>
            </div>

            {description && (
              <Prose className={styles.Description} html={description} />
            )}
          </div>
        )}
        <div className={styles.MainContent}>
          <h2 className="heading2">News</h2>
          {articles.length > 0 && (
            <NewsMainContent
              featuredItem={featuredItem}
              otherItems={otherItems}
            />
          )}
          {articles.length === 0 && (
            <div className={styles.Description}>
              Sorry, there's no matches for your search
            </div>
          )}
          {loadMore && (
            <LoadMore
              text="Load More"
              loadMoreMethod={loadMoreMethod}
              visible
              busy={loadingMore}
              onLoadMore={onLoadMore}
            />
          )}
        </div>
      </div>
    </div>
  )
}

NewsListingV2.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  showFeatured: PropTypes.bool,
  searchTerm: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      ctaLink: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      isFeaturedNew: PropTypes.bool,
      readTimeInMins: PropTypes.number
    })
  ),
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSearchChange: PropTypes.func
}

export default NewsListingV2
