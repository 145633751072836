import React from 'react'
import { string, shape, arrayOf, bool } from 'prop-types'
import imagePropTypes from '@/shape/image'

import MediaItem from './component/MediaItem'

const MediaList = ({ items, showImages, designV2 }) => {
  return (
    <div>
      {items &&
        items.map((item, i) => {
          item.img =
            item &&
            item.images &&
            item.images.thumbnail_1x1_225 &&
            item.images.thumbnail_1x1_225[0]
          return (
            <MediaItem
              {...item}
              key={`MediaItem-${item.title}-${i}`}
              showImage={showImages}
              designV2={designV2}
            />
          )
        })}
    </div>
  )
}

MediaList.propTypes = {
  showImages: bool,
  designV2: bool,
  items: arrayOf(
    shape({
      headline: string.isRequired,
      fullUrlPath: string.isRequired,
      displayDate: string,
      sell: string,
      rating: string,
      img: shape(imagePropTypes)
    })
  )
}

export default MediaList
