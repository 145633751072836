import React, { useState, useEffect, useCallback } from 'react'
import styles from '../SpotTheIssues.module.scss'
import classNames from 'classnames'
import Type from '@/component/Primitive/Type'
import Icon from '@/component/Primitive/Icon'
import { shape, string } from 'prop-types'

const IssueItemModal = ({ item, onClose }) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.IssueItemModalContent}`)) {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClose])

  return (
    <div className={styles.IssueItemModal}>
      <button onClick={handleClose} className={styles.IssueItemModalClose}>
        <Icon
          type="close"
          className={styles.CloseIcon}
          a11yText="Close"
          width={40}
        />
      </button>
      <div className={styles.IssueItemModalContent}>
        <Type className={styles.IssueItemModalHeading} size="display0">
          {item.label}
        </Type>
        <Type size="base-large" color="black">
          {item.description}
        </Type>
      </div>
    </div>
  )
}

const IssueItem = ({ item }) => {
  const [showDescription, setShowDescription] = useState(false)

  const toggle = () => {
    setShowDescription(!showDescription)
  }

  return (
    <>
      <button className={styles.IssueItem} onClick={toggle}>
        <div
          className={classNames(
            styles.IssueItemLabelWrap,
            showDescription && styles.IssueItemLabelWrapActive
          )}
        >
          <Type className={styles.IssueItemLabel} color="white" size="display0">
            <span className={styles.IssueItemLabelEmoji}>{item.emoji}</span>
            <span className={styles.IssueItemLabelType}>{item.type}</span>
          </Type>
          <Icon type="info" width={30} height={30} a11yText="Get Info" />
        </div>
      </button>
      {showDescription && (
        <>
          <IssueItemModal item={item} onClose={toggle} />
          <div className={styles.ModalOverlay} />
        </>
      )}
    </>
  )
}

IssueItem.propTypes = {
  item: shape({
    id: string,
    label: string,
    description: string,
    emoji: string
  }).isRequired
}

IssueItemModal.propTypes = {
  item: shape({
    id: string,
    label: string,
    description: string,
    emoji: string
  }).isRequired,
  onClose: () => {}
}

export default IssueItem
