import React from 'react'
import classNames from 'classnames'
import create2dArray from '2d-array'

import Grid from '@/component/Primitive/Grid'
import Container from '@/component/Primitive/Container'
import ContentSummary from '@/component/Primitive/ContentSummary'
import styles from '../theme/CBBFCContentSummaryGrid.module.scss'
import contentSummaryPropTypes from '@/shape/content-summary'
import { arrayOf, string, shape } from 'prop-types'
import { RatingContextProvider } from '@/component/Context/RatingContext'

const itemWidths = [[50], [50, 40], [30, 30, 40]]

const ContentSummaryGrid = ({ items }) => {
  const nestedItems = create2dArray(items, 3)
  return (
    <Container center size="huge">
      <Grid>
        {nestedItems.map((items, i) => {
          return (
            <Grid.Item key={`ContentSummary-${i}`}>
              <div
                className={classNames(
                  styles.ContentSummaryGrid,
                  items.length === 1 && styles.centered,
                  items.length > 1 && styles.staggered
                )}
              >
                {items &&
                  items.map((item, i) => (
                    <RatingContextProvider key={i} rating={item.contentRating}>
                      <ContentSummary
                        index={i}
                        {...item}
                        width={itemWidths[items.length - 1][i]}
                      />
                    </RatingContextProvider>
                  ))}
              </div>
            </Grid.Item>
          )
        })}
      </Grid>
    </Container>
  )
}

ContentSummaryGrid.propTypes = {
  items: arrayOf(shape(contentSummaryPropTypes)),
  title: string,
  subtitle: string
}

export default ContentSummaryGrid
