import React from 'react'
import RatingsSubNavigation from '@/component/Common/RatingsSubNavigation'
import { useThemeContext } from '@/component/Context/ThemeContext'

const RatingsNavigation = () => {
  const theme = useThemeContext()
  return <RatingsSubNavigation theme={theme} />
}

export default RatingsNavigation
