const createQuery = () => `
  ... on PageSubNavWidget {
    id
    title
    links{
      ctaLabel
      ctaLink
    }
  }
`
export default createQuery
