import React, { useState } from 'react'
import { string, arrayOf, shape } from 'prop-types'

import hat from 'hat'
import ButtonStandard from '@/component/Primitive/ButtonStandard'

import GameWidget from './component/GameWidget'

export const ratingList = ['u', 'pg', '12', '12a']

const SpotTheIssues = ({
  id,
  title,
  videoId,
  timestamps,
  issues,
  overallRating,
  behindTheClassification,
  videoProvider
}) => {
  const [playView, setPlayView] = useState(true)

  const handleAgeFormSubmit = (data) => {
    const id = hat()
    localStorage.setItem('user', JSON.stringify({ ...data, id }))
  }

  const handleClosePlayView = () => {
    setPlayView(false)
  }

  const toggle = () => {
    setPlayView(!playView)
  }

  return (
    <>
      {playView && (
        <GameWidget
          id={id}
          title={title}
          videoId={videoId}
          issues={issues}
          onClose={handleClosePlayView}
          onSubmit={handleAgeFormSubmit}
          timestamps={timestamps}
          overallRating={overallRating}
          behindTheClassification={behindTheClassification}
          videoProvider={videoProvider}
        />
      )}
      <ButtonStandard onClick={toggle}>Play!</ButtonStandard>
    </>
  )
}

SpotTheIssues.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  videoId: string.isRequired,
  timestamps: arrayOf(
    shape({
      _id: string,
      timestamp: string,
      issue: string
    })
  ),
  issues: arrayOf(
    shape({
      type: string,
      emoji: string.isRequired,
      description: string
    })
  ),
  overallRating: string,
  behindTheClassification: string,
  videoProvider: string
}

export default SpotTheIssues
