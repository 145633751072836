import React from 'react'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import { func, string, bool } from 'prop-types'

import CustomCheckControl from '@/component/Primitive/CheckControl/CustomCheckControl'
import Type from '@/component/Primitive/Type'
import AgeRating from '@/component/Primitive/AgeRating'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'

import styles from './Option.module.scss'

const Option = ({ name, rating, handleThemeSelect, isChecked }) => {
  const isDark = useDarkContext()
  if (rating)
    return (
      <label
        className={classNames(
          styles.RatingOption,
          isChecked && styles.checked,
          isDark && styles.isDark
        )}
        htmlFor={`RatingOption-${rating}`}
      >
        <AgeRating size={2} rating={rating} className={styles.Rating} />
        <VisuallyHidden>
          <CustomCheckControl
            name={rating}
            type="checkbox"
            id={`RatingOption-${rating}`}
            value={rating}
            onChange={() => handleThemeSelect(rating)}
          />
        </VisuallyHidden>
      </label>
    )
  else
    return (
      <label className={classNames(styles.Option, isDark && styles.isDark)}>
        <CustomCheckControl
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={() => handleThemeSelect(name)}
          value={name}
        />
        <Type as="span" size="base" className={styles.Title}>
          {name}
        </Type>
      </label>
    )
}

Option.propTypes = {
  name: string,
  rating: string,
  handleThemeSelect: func,
  isChecked: bool
}

export default Option
