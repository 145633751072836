import React from 'react'
import { node, string, bool } from 'prop-types'
import classNames from 'classnames'

import styles from './CheckControlGroup.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const CheckControlGroup = ({ a11yLabel, children, row }) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classNames(
        styles.CheckControlGroup,
        row && styles.row,
        isDark && styles.dark
      )}
      role="group"
      aria-label={a11yLabel}
    >
      {children}
    </div>
  )
}

CheckControlGroup.defaultProps = {
  row: false
}

CheckControlGroup.propTypes = {
  a11yLabel: string.isRequired,
  children: node.isRequired,
  row: bool
}

export default CheckControlGroup
