import React from 'react'
import { string, shape } from 'prop-types'
import imagePropTypes from '@/shape/image'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import Type from '@/component/Primitive/Type'
import Surround from '@/component/Primitive/Surround'

import styles from './Slide.module.scss'

const Slide = ({ title, image, link }) => {
  const isDark = useDarkContext()
  return (
    <div className={classNames(styles.Slide, isDark && styles.isDark)}>
      <a href={link} target="_blank" rel="noreferrer">
        <ResponsiveMedia ratio={3 / 5}>
          <Surround rounded className={styles.Wrapper}>
            <ResponsiveImage {...image} />
          </Surround>
        </ResponsiveMedia>
      </a>
      <div className={styles.Information}>
        <Type as="h3" size="base-large" className={styles.Title}>
          {title}
        </Type>
      </div>
    </div>
  )
}

export default Slide

Slide.propTypes = {
  title: string,
  link: string,
  image: shape(imagePropTypes)
}
