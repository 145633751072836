import React from 'react'
import { arrayOf, string, shape } from 'prop-types'

import SubNav from '@/component/Common/SubNavigation'

const ManualSubNavigation = (props) => {
  const { title, links } = props
  const formattedLinks =
    links &&
    links.map((link) => {
      const as = link.link.toLowerCase()
      return {
        name: link.label,
        to: link.type ? link.type : as,
        as
      }
    })
  return (
    <>
      <SubNav hasPointer title={title} links={formattedLinks} isOrange />
    </>
  )
}

ManualSubNavigation.propTypes = {
  title: string,
  links: arrayOf(shape({ label: string, link: string }))
}

export default ManualSubNavigation
