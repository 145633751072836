import React from 'react'
import PropTypes from 'prop-types'
import styles from './News.module.scss'
import { compareDesc, format } from 'date-fns'

import NewsMainContent from './components/NewsMainContent'
import LoadMore from '../LoadMore'

const News = ({
  title,
  ctaLabel,
  ctaLink,
  showFeatured,
  articles,
  loadMore,
  loadMoreMethod,
  loadingMore,
  onLoadMore
}) => {
  const sortedItems = articles
    .sort((a, b) => compareDesc(a.date, b.date)) // sort by date
    .map((item) => ({
      ...item,
      date: format(item.date, 'do MMMM yyy')
    }))
  const featuredItem = showFeatured
    ? sortedItems.find((item) => item.isFeaturedNew)
    : null
  const otherItems = sortedItems.filter(
    (item) => !featuredItem || item.id !== featuredItem.id
  )

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.TopContent}>
          <h2 className="heading2">{title}</h2>
          <a className={styles.Link} href={ctaLink}>
            {ctaLabel}
          </a>
        </div>
        <NewsMainContent featuredItem={featuredItem} otherItems={otherItems} />
        {loadMore && (
          <LoadMore
            text="Load More"
            loadMoreMethod={loadMoreMethod}
            visible
            busy={loadingMore}
            onLoadMore={onLoadMore}
          />
        )}
      </div>
    </div>
  )
}

News.propTypes = {
  title: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  showFeatured: PropTypes.bool,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      ctaLink: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      isFeaturedNew: PropTypes.bool,
      readTimeInMins: PropTypes.number
    })
  ),
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func
}

export default News
