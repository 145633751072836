import React from 'react'
import { arrayOf, shape, string, bool, func, object, array } from 'prop-types'
import Results from '@/component/Common/Poll/component/Results'

import Questions from './component/Questions'

import styles from './Poll.module.scss'
import classNames from 'classnames'

const Poll = ({
  form,
  handleOnSubmit,
  handleOnChange,
  validationError,
  serverError,
  successMessage,
  waiting,
  results,
  formStored,
  designV2,
  padding
}) => {
  const { questions, description, resultsTitle, title } = form || {}
  return (
    <div
      className={classNames(
        styles.Poll,
        padding && 'vertical-padding--section horizontal-padding--section',
        designV2 && 'borderRadius--V2'
      )}
    >
      {!formStored && !successMessage && (
        <Questions
          questions={questions}
          description={description}
          handleOnChange={handleOnChange}
          title={title}
          handleOnSubmit={handleOnSubmit}
          validationError={validationError}
          serverError={serverError}
          waiting={waiting}
          designV2={designV2}
        />
      )}
      {(formStored || successMessage) && results && (
        <Results
          questions={results}
          description={description}
          resultsTitle={resultsTitle}
          designV2={designV2}
        />
      )}
    </div>
  )
}

Poll.propTypes = {
  formStored: bool,
  handleOnChange: func.isRequired,
  handleOnSubmit: func.isRequired,
  waiting: bool,
  designV2: bool,
  padding: bool,
  results: array,
  successMessage: string,
  validationError: object,
  serverError: object,
  form: shape({
    title: string,
    description: string,
    questions: arrayOf(
      shape({
        question: string,
        required: bool,
        inputType: string,
        responses: arrayOf(
          shape({
            response: string
          })
        )
      })
    )
  }).isRequired
}

export default Poll
