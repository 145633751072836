const createQuery = () => `
  ... on ArticleListingV2Widget {
    id
    title
    description
    searchPlaceholder
    lists
    limit
    loadMore
    loadMoreMethod
    initialPageSize
    loadMorePageSize
    tagFilters {
      tag
    }
  }
`
export default createQuery
