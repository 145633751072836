import React from 'react'
import { string, shape, object, arrayOf, bool } from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'

import SmartLink from '@/component/Primitive/SmartLink'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import AgeRating from '@/component/Primitive/AgeRating'
import Type from '@/component/Primitive/Type'
import Container from '@/component/Primitive/Container'
import Grid from '@/component/Primitive/Grid'

import styles from '../theme/BBFCArticleHeader.module.scss'

const ArticleHeader = ({ article, sections, shouldDisplayDate, isDark }) => {
  if (!article) return null
  const { headline, contentRating, displayDate, images } = article
  const image = images && images.hero_5x2_1920 && images.hero_5x2_1920[0]
  const tabletImage = images && images.hero_5x2_960 && images.hero_5x2_960[0]
  const mobileImage = images && images.hero_5x2_550 && images.hero_5x2_550[0]
  const url = image && image.url
  const tabletUrl = tabletImage && tabletImage.url
  const mobileUrl = mobileImage && mobileImage.url
  const img = {
    src: url,
    alt: (image && image.alt) || '',
    sizes: ['(min-width: 960px) 1920px', '(min-width: 550px) 960px', '500px'],
    srcSet: [
      {
        width: 1920,
        src: url
      },
      {
        width: 960,
        src: tabletUrl
      },
      {
        width: 550,
        src: mobileUrl
      }
    ]
  }
  const isValidDate = moment(displayDate).isValid()
  const formattedPublishedDate = isValidDate
    ? moment(displayDate).format('Do MMMM YYYY')
    : ''
  const isRateATrailer = article.articleType === 'rateATrailer'
  return (
    <>
      <div
        className={classnames(
          styles.ArticleHeader,
          image && styles.hasImage,
          isDark && styles.isDark,
          isRateATrailer && styles.padded
        )}
      >
        {image && <ResponsiveImage className={styles.Image} {...img} />}
        <div className={styles.Strip}>
          <Container center gutter size="huge">
            <Grid vAlign="middle" gutter="large" reverse={!sections}>
              {sections && (
                <Grid.Item width={[1, 1, 1 / 3]}>
                  <div className={styles.Information}>
                    <div>
                      {sections &&
                        sections.map((breadCrumb, i) => (
                          <Type
                            as="span"
                            size="base"
                            className={styles.BreadCrumb}
                            key={`Breadcrumb-${breadCrumb.name}-${i}`}
                            bold
                          >
                            <SmartLink
                              to="/section"
                              as={breadCrumb.fullUrlPath}
                            >
                              {breadCrumb.name}
                            </SmartLink>
                          </Type>
                        ))}
                    </div>
                    {isValidDate && shouldDisplayDate && (
                      <Type as="div" size="base">
                        <i>Published:</i> {formattedPublishedDate}
                      </Type>
                    )}
                  </div>
                </Grid.Item>
              )}
              <Grid.Item width={[1, 1, 2 / 3]}>
                <div className={styles.TitleWrapper}>
                  {contentRating && !isRateATrailer && (
                    <AgeRating
                      rating={contentRating}
                      className={styles.AgeRating}
                    />
                  )}
                  <Type as="h1" size="display2">
                    {headline}
                  </Type>
                </div>
              </Grid.Item>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  )
}

ArticleHeader.defaultProps = {
  shouldDisplayDate: true
}

ArticleHeader.propTypes = {
  article: shape({
    headline: string,
    contentRating: string,
    displayDate: string,
    images: object
  }),
  sections: arrayOf(
    shape({
      fullUrlPath: string,
      name: string
    })
  ),
  shouldDisplayDate: bool,
  isDark: bool
}

export default ArticleHeader
