import React from 'react'
import downloadPropTypes from '@/shape/download'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive//Type'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import SmartLink from '@/component/Primitive/SmartLink'

import styles from './DownloadItem.module.scss'

const DownloadItem = ({
  title,
  fileSize,
  downloadText,
  fileType,
  img,
  url,
  list
}) => {
  const isDark = useDarkContext()
  return (
    <SmartLink
      className={classNames(styles.DownloadItem, isDark && styles.isDark)}
      href={url}
    >
      {!list && (
        <ResponsiveImage {...img} className={styles.DownloadItemImage} />
      )}
      <div className={styles.DownloadItemInfoWrapper}>
        {title && (
          <Type as="h4" size="base" bold className={styles.DownloadItemTitle}>
            {title}
          </Type>
        )}
      </div>
    </SmartLink>
  )
}

export default DownloadItem

DownloadItem.propTypes = {
  ...downloadPropTypes
}
