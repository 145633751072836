import React from 'react'
import { arrayOf, shape } from 'prop-types'
import imagePropTypes from '@/shape/image'

import Carousel from '@/component/Common/Carousel'
import Slide from './component/Slide'
import inlineImageMapper from './lib/mapper'

const multipleImageMapper = {
  '16:9': 'inline_16x9_577',
  '1:1': 'inline_1x1_577',
  Free: 'inline_free_577'
}

const InlineImageCarousel = ({ images }) => {
  const mappedImages = inlineImageMapper(multipleImageMapper, images)
  return (
    <Carousel bottomControls showPageCount>
      {mappedImages &&
        mappedImages.map((image, i) => (
          <Slide {...image} key={`Slide-${image.alt}-${i}`} />
        ))}
    </Carousel>
  )
}

InlineImageCarousel.propTypes = {
  images: arrayOf(shape(imagePropTypes))
}

export default InlineImageCarousel
