const createQuery = () => `
  query NewsListingV2LoadMore($lists: [String], $page: Int, $limit: Int, $searchTerm: String) {
    loadMore(lists: $lists, page: $page, limit: $limit, searchTerm: $searchTerm) {
      articles {
        _id
        headline
        shortHeadline
        fullUrlPath
        displayDate
        readTimeInMins
        sell
        standfirst
      }
    }
  }
`

export default createQuery
