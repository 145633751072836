import PropTypes from 'prop-types'
import React from 'react'
import Prose from '@/component/Primitive/Prose'
import Mailchimp from '@/component/Common/Mailchimp'

import styles from './Mailchimp.module.scss'

const MailchimpWidget = (props) => {
  const { title, audienceId } = props
  return (
    <div className={styles.Mailchimp}>
      <Prose color="black">
        <h1>{title}</h1>
        <br />
      </Prose>
      <Mailchimp audienceId={audienceId} />
    </div>
  )
}

MailchimpWidget.propTypes = {
  title: PropTypes.string,
  audienceId: PropTypes.string
}

export default MailchimpWidget
