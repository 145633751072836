import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Type from '@/component/Primitive/Type'
import Prose from '@/component/Primitive/Prose'
import Icon from '@/component/Primitive/Icon'
import AgeRating from '@/component/Primitive/AgeRating'

import Leaderboard from './Leaderboard'

import { string, func } from 'prop-types'

import styles from './ScoreStep.module.scss'

const ScoreStep = ({
  id,
  title,
  score,
  issueList,
  overallRating,
  gameScore,
  behindTheClassification,
  playAgainHandle
}) => {
  const [leaderboardData, setLeaderboardData] = useState(null)
  const [todayDate, setTodayDate] = useState('')

  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await fetch(`/api/get-spot-the-issue/${id}`, {
          headers: { 'content-type': 'application/json' },
          method: 'GET'
        })
        const responseBody = await response.json()

        const leaderboardData = responseBody.data

        setLeaderboardData(leaderboardData)
      } catch (error) {
        console.error('Error fetching leaderboard data:', error)
      }
    }

    fetchLeaderboardData()

    const today = new Date()
    const formattedDate = today.toLocaleDateString('en-GB')
    setTodayDate(formattedDate)
  }, [id])

  const userPosition =
    leaderboardData &&
    leaderboardData.findIndex((item) => item.id === user.id) + 1

  const getRatingClassName = (rating) => {
    switch (rating) {
      case 'U':
        return 'ratingU'
      case 'PG':
        return 'ratingPG'
      case '12':
        return 'rating12'
      case '12A':
        return 'rating12A'
      default:
        return ''
    }
  }

  const ratingClassName = getRatingClassName(overallRating)

  return (
    <>
      <div className={styles.ScoreStep}>
        <div className={styles.ScoreDetailsContainer}>
          <div className={styles.ScoreDetailsHeader}>
            <Icon
              className={classNames(styles.TabletLogo, styles.Logo)}
              a11yText="BBFC Logo"
              type="bbfc-logo--white"
              width={117}
            />
            <div>
              <Type
                bold
                size="base-large"
                color="white"
                className={styles.ScoreDetailsHeading}
              >
                {title}
              </Type>
            </div>
          </div>
          <div className={styles.ScoreDetails}>
            <AgeRating className={styles.AgeRating} rating={overallRating} />
            <div className={styles.ScoreDetailsContent}>
              <Type size="display0">
                You Scored{' '}
                <span className={styles.Point}>{score.totalScore} Points</span>
              </Type>
              <div className={styles.PointDetails}>
                <Type className={styles.Point}>
                  {Array.from(
                    new Set(issueList.map((issue) => issue.issue))
                  ).map((label, index, array) => (
                    <span key={index}>
                      {label.toLowerCase()}
                      {index !== array.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </Type>
              </div>
            </div>
          </div>
          <div className={styles.ScoreDetailsFooter}>
            <div className={styles.ScoreDetailsFooterLeft}>
              <span className={styles.PlayerName}>{user.name}</span>
              <Type size="small" className={styles.Player}>
                Player
              </Type>
            </div>
            <div className={styles.ScoreDetailsFooterRight}>
              <Type size="small">{todayDate}</Type>
            </div>
          </div>
        </div>
      </div>
      <Leaderboard
        leaderboardData={leaderboardData}
        userPosition={userPosition}
        user={user}
        score={score}
        title={title}
        playAgainHandle={playAgainHandle}
      />
      <div className={styles.ScoreStepOthers}>
        <Type
          size="display1"
          className={styles.BehindClassification}
          color="teal"
        >
          Behind the age rating
        </Type>
        <Prose
          html={behindTheClassification}
          className={classNames(
            styles.BehindClassificationDescription,
            styles[`BehindClassificationDescription${ratingClassName}`]
          )}
        />
      </div>
    </>
  )
}

ScoreStep.propTypes = {
  id: string,
  title: string,
  score: string,
  issueList: string,
  overallRating: string,
  gameScore: string,
  behindTheClassification: string,
  playAgainHandle: func
}

export default ScoreStep
