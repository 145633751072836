const createQuery = () => `
... on ManualSubNavigationWidget {
  title
  links {
   label
   link
   type
  }
}
`
export default createQuery
