import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './HomeHero.module.scss'

import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

import classNames from 'classnames'
import HalfImageItem from './components/HalfImageItem/HalfImageItem'
import FullImageItem from './components/FullImageItem/FullImageItem'

const HomeHero = ({ items }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      slidesToScroll: 1,
      containScroll: 'keepSnaps',
      skipSnaps: true,
      loop: true
    },
    [
      Autoplay({
        playOnInit: true,
        delay: 10000
      })
    ]
  )
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
      emblaApi.plugins().autoplay.reset()
    },
    [emblaApi]
  )
  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)

    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return (
    <div className={styles.HeroWrap}>
      <div className={styles.CarouselContainer}>
        <div className={styles.CarouselContent} ref={emblaRef}>
          <div className={styles.CarouselContentWrap}>
            {items?.map((item, index) => {
              return item.displayType === 'halfImage' ? (
                <HalfImageItem key={index} {...item} />
              ) : (
                <FullImageItem key={index} {...item} />
              )
            })}
          </div>
        </div>
      </div>
      {items?.length > 1 && (
        <div className={styles.CarouselDots}>
          {scrollSnaps?.map((_, index) => (
            <button
              aria-label={items[index]?.title}
              className={classNames(
                styles.CarouselDot,
                index === selectedIndex && styles.ActiveCarouselDot
              )}
              key={index}
              onClick={() => onDotButtonClick(index)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

HomeHero.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subHeading: PropTypes.string,
      rating: PropTypes.string,
      displayType: PropTypes.oneOf(['halfImage', 'fullImage']),
      url: PropTypes.string,
      image: PropTypes.shape({
        desktopImage: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  )
}

export default HomeHero
