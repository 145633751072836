import React from 'react'
import { array, bool, node } from 'prop-types'

import BioGrid from '@/component/Common/BioGrid/'
import classNames from 'classnames'

const BioGridWidgetWrap = ({ padding, designV2, children }) => {
  if (designV2 || padding) {
    return (
      <div
        className={classNames(
          designV2 && 'borderRadius--V2',
          padding && 'vertical-padding--section horizontal-padding--section'
        )}
      >
        {children}
      </div>
    )
  }
  return <>{children}</>
}

const BioGridWidget = ({ bios, designV2, padding }) => {
  const formattedBios =
    bios &&
    bios.map((bio) => {
      const image =
        bio &&
        bio.images &&
        bio.images.profile_1x1_300 &&
        bio.images.profile_1x1_300[0]

      return {
        name: bio.name,
        title: bio.jobTitle,
        content: bio.biography,
        img: {
          src: image && image.url,
          alt: image && image.alt,
          srcSet: []
        }
      }
    })
  return (
    <BioGridWidgetWrap designV2={designV2} padding={padding}>
      <BioGrid items={formattedBios} designV2={designV2} />
    </BioGridWidgetWrap>
  )
}
BioGridWidgetWrap.propTypes = {
  children: node,
  designV2: bool,
  padding: bool
}
BioGridWidget.propTypes = {
  bios: array,
  designV2: bool,
  padding: bool
}

export default BioGridWidget
