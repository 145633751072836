const createQuery = () => `
  ... on SubNavigationWidget {
    title
    navItems {
      name
      link
    }
  }
`
export default createQuery
