import React from 'react'
import propTypes from 'prop-types'
import RelatedArticlesV2 from '../../BBFC/RelatedArticlesV2'

const RelatedArticlesV2Widget = ({ title, article, articles }) => {
  if (!articles || !articles.length) {
    return null
  }

  const formattedArticles =
    articles &&
    articles.map((article) => {
      const img =
        article &&
        article.images &&
        article.images.thumbnail_3x2_438 &&
        article.images.thumbnail_3x2_438[0]
      const url = img && img.url
      const alt = img && img.alt
      return {
        img: { src: url, alt: alt || '' },
        heading: article && article.headline,
        body: article && article.sell,
        link: article && article.fullUrlPath,
        contentRating: article && article.contentRating
      }
    })

  return (
    <RelatedArticlesV2
      title={title}
      items={formattedArticles}
      hideTitle={article?.layout !== 'articleV2'}
    />
  )
}

RelatedArticlesV2Widget.propTypes = {
  title: propTypes.string,
  article: propTypes.object,
  articles: propTypes.array
}

export default RelatedArticlesV2Widget
