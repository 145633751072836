import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListicleList.module.scss'
import ListicleListItem from './ListicleListItem'
import ReleaseListicleListItem from './ReleaseListicleListItem'

const ListicleList = ({ items }) => {
  return (
    <div className={styles.ItemsList}>
      {items.map((item, index) =>
        item.releases && item.releases[0] ? (
          <ReleaseListicleListItem key={index} item={item} />
        ) : (
          <ListicleListItem key={index} item={item} />
        )
      )}
    </div>
  )
}

ListicleList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.shape({
        _573: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string
          })
        )
      }),
      releases: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          classification: PropTypes.string,
          releaseDate: PropTypes.string,
          shortFormInsight: PropTypes.string,
          synopsis: PropTypes.string,
          slug: PropTypes.string,
          images: PropTypes.shape({
            _573: PropTypes.shape({
              url: PropTypes.string
            })
          }),
          visualAdviceLayer: PropTypes.shape({
            overview: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                strength: PropTypes.string
              })
            )
          })
        })
      ),
      title: PropTypes.string,
      description: PropTypes.string
    })
  )
}

export default ListicleList
