import React from 'react'
import styles from './WatchStep.module.scss'
import Type from '@/component/Primitive/Type'
import { number } from 'prop-types'

const CountDownRenderer = ({ minutes, seconds }) => {
  // Format seconds to always have 2 digits
  const formattedSeconds = String(seconds).padStart(2, '0')
  return (
    <Type bold size="base-large" color="white" className={styles.Time}>
      {minutes}:{formattedSeconds}
    </Type>
  )
}

CountDownRenderer.propTypes = {
  minutes: number,
  seconds: number
}

export default CountDownRenderer
