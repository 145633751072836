import React from 'react'
import PropTypes from 'prop-types'

import AboutThisFilm from '@/component/Common/AboutThisFilm'

const AboutThisFilmWidget = ({ article }) => {
  const releases = article && article.releases

  if (!releases || releases.length === 0) {
    return null
  }

  return <AboutThisFilm release={releases[0]} />
}

AboutThisFilmWidget.propTypes = {
  article: PropTypes.object
}

export default AboutThisFilmWidget
