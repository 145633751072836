import React from 'react'
import { array } from 'prop-types'
import Type from '@/component/Primitive/Type'
import Container from '@/component/Primitive/Container'
import styles from './BreadcrumbV2.module.scss'
import SmartLink from '../../Primitive/SmartLink'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const BreadcrumbV2Widget = (props) => {
  const { sections } = props
  const isDark = useDarkContext()
  return (
    <div className={styles.BreadCrumbWrapper}>
      <Container
        center
        size="huge"
        className={classNames(styles.Container, isDark && styles.dark)}
      >
        <a className={styles.NavLink} href="/">
          Home
        </a>
        <span> / </span>
        {sections &&
          sections.map((breadCrumb, i) => {
            const isCurrentPage = i === sections.length - 1
            return (
              <Type
                key={`Breadcrumb-${breadCrumb.name}-${i}`}
                as="span"
                size="base"
                bold={i === sections.length - 1}
                className={styles.BreadCrumb}
              >
                {isCurrentPage ? (
                  breadCrumb.name
                ) : (
                  <SmartLink to="/section" as={breadCrumb.fullUrlPath}>
                    {breadCrumb.name}
                  </SmartLink>
                )}
              </Type>
            )
          })}
      </Container>
    </div>
  )
}

BreadcrumbV2Widget.propTypes = {
  sections: array
}

export default BreadcrumbV2Widget
