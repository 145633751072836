import React from 'react'
import styles from '../News.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const NewsMainContent = ({ featuredItem, otherItems }) => {
  return (
    <div className={styles.MainContent}>
      {featuredItem && (
        <a href={featuredItem.ctaLink} className={styles.FeaturedNewsWrap}>
          <div className={classNames(styles.NewsDate, styles.FeaturedNewsDate)}>
            {featuredItem.date}
          </div>
          <div className={styles.FeaturedNews}>
            <div>
              <h3 className={styles.FeaturedNewsTitle}>{featuredItem.title}</h3>
              <div className={styles.NewsDate}>
                {featuredItem.readTimeInMins} min
                {featuredItem.readTimeInMins > 1 && 's'} read
              </div>
            </div>
            <div className={styles.FeaturedNewsRightCol}>
              <div
                className={classNames(
                  styles.NewsDescription,
                  styles.FeaturedNewsDescription
                )}
              >
                {featuredItem.shortDescription}
              </div>
              <div className={styles.FeaturedNewsCTALabel}>
                {featuredItem.ctaLabel || 'Read more'}
              </div>
            </div>
          </div>
        </a>
      )}
      <div className={styles.NewsList}>
        {otherItems.map((item) => (
          <a href={item.ctaLink} key={item.id} className={styles.NewsItem}>
            <h3 className={styles.NewsItemTitle}>{item.title}</h3>
            <div className={styles.NewsDescription}>
              {item.shortDescription}
            </div>
            <div className={styles.NewsDate}>{item.date}</div>
          </a>
        ))}
      </div>
    </div>
  )
}
NewsMainContent.propTypes = {
  featuredItem: PropTypes.shape({
    ctaLink: PropTypes.string,
    ctaLabel: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    readTimeInMins: PropTypes.number,
    shortDescription: PropTypes.string
  }),
  otherItems: PropTypes.array
}
export default NewsMainContent
