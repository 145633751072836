import React from 'react'
import propTypes from 'prop-types'
import Poll from '@/component/Common/Poll'
import createPollSubmissionContainer from '../../Common/FormBuilder/poll-submission'
import buildSchema from '../Form/schema'

const PollWidget = ({ poll, section, designV2, padding }) => {
  const PollSubmissionContainer = createPollSubmissionContainer({
    Component: (props) => <Poll {...props} />,
    form: poll,
    section,
    buildSchema,
    submitUrl: `/api/submit-poll/`,
    getUrl: `/api/get-poll/`
  })
  return <PollSubmissionContainer designV2={designV2} padding={padding} />
}

PollWidget.propTypes = {
  section: propTypes.object,
  poll: propTypes.array,
  designV2: propTypes.bool,
  padding: propTypes.bool
}

export default PollWidget
