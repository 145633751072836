import React from 'react'
import downloadsListPropTypes from '@/shape/downloads-list'

import ListItem from './component/ListItem'
import Type from '@/component/Primitive/Type'

import styles from './DownloadsList.module.scss'
import List from '@/component/Primitive/List'
import Container from '@/component/Primitive/Container'

const buildTitle = ({ downloadExtension, downloadSize, title, name }) => {
  const info = [downloadExtension, downloadSize]
    .filter((item) => item)
    .join(', ')
    .toUpperCase()
  const formattedInfo = info ? ` (${info})` : ''
  const fileName = name && name.split('.')[0]
  return `${title || fileName || 'File'}${formattedInfo}`
}

const DownloadsList = ({ title, downloads }) => {
  if (!downloads || !downloads.length) return null
  return (
    <>
      <div className={styles.DownloadsList}>
        <Container gutter>
          <Type as="h3" size="display0" color="teal" className={styles.Title}>
            {title}
          </Type>
          <List unstyled>
            {downloads &&
              downloads.map((download, i) => {
                const titleWithInfo = buildTitle(download)
                return (
                  <ListItem
                    title={titleWithInfo}
                    url={download.downloadUrl}
                    key={`Download-${download.title}-${i}`}
                  />
                )
              })}
          </List>
        </Container>
      </div>
    </>
  )
}

DownloadsList.propTypes = downloadsListPropTypes

export default DownloadsList
