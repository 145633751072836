const createQuery = () => `
... on FaqWidget {
  title
  description
  designV2
  padding
  faqs {
   question
   answer
  }
}
`
export default createQuery
