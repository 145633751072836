import React from 'react'
import BBFCArticleHeader from './component/BBFCArticleHeader'
import CBBFCArticleHeader from './component/CBBFCArticleHeader'
import { useThemeContext } from '@/component/Context/ThemeContext'
import { useDarkContext } from '@/component/Context/DarkContext'
import BBFCArticleHeaderV2 from './component/redesign24/BBFCArticleHeaderV2'

const ArticleHeader = (props) => {
  const theme = useThemeContext()
  const isDark = useDarkContext()
  const bbfcV2 = props.article.layout === 'articleV2'

  const componentMap = {
    bbfc: bbfcV2 ? (
      <BBFCArticleHeaderV2 {...props} />
    ) : (
      <BBFCArticleHeader {...props} isDark={isDark} />
    ),
    cbbfc: <CBBFCArticleHeader {...props} isDark={isDark} />
  }

  return componentMap[theme]
}

export default ArticleHeader
