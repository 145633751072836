import React from 'react'
import classNames from 'classnames'
import SmartLink from '@/component/Primitive/SmartLink'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Surround from '@/component/Primitive/Surround'
import styles from '../theme/CBBFCContentSummary.module.scss'
import contentSummaryPropTypes from '@/shape/content-summary'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import Icon from '@/component/Primitive/Icon'
import Type from '@/component/Primitive/Type'
import RatingColor from '@/component/Common/RatingColor'

const ContentSummary = ({ link, img, heading, body, width, color, index }) => {
  const Wrapper = link ? SmartLink : 'div'
  const { alt, src, srcSet } = img

  return (
    <div
      className={styles.ContentSummaryWrapper}
      style={{ width: `${width}%` }}
    >
      {index === 0 && (
        <div className={styles.Triangles}>
          <Icon a11yText="Triangles" type="triangles" />
        </div>
      )}
      {index !== 0 && (
        <div className={styles.Squiggle}>
          <Icon
            className={styles.SquiggleIcon}
            width={70}
            type="squiggle"
            a11yText="Squiggle"
          />
        </div>
      )}
      <RatingColor property="fill">
        <Icon type="triangle" className={styles.Pointer} a11yText="Pointer" />
      </RatingColor>
      <Surround
        rounded
        className={classNames(styles.ContentSummary, styles[color])}
      >
        <RatingColor as="div" property="background-color">
          <Wrapper to="/article" as={link}>
            <div className={styles.ContentSummaryInner}>
              <ResponsiveMedia ratio={9 / 16}>
                <ResponsiveImage alt={alt} src={src} srcSet={srcSet} />
              </ResponsiveMedia>
              <Type as="h3" size="title" className={styles.Heading}>
                {heading}
              </Type>
              {body && (
                <Type as="p" size="base-large" className={styles.Body}>
                  {body}
                </Type>
              )}
            </div>
          </Wrapper>
        </RatingColor>
      </Surround>
    </div>
  )
}

ContentSummary.defaultProps = {
  color: 'green'
}

ContentSummary.propTypes = contentSummaryPropTypes

export default ContentSummary
