import React from 'react'
import { arrayOf, string, shape, bool } from 'prop-types'
import contentSummaryPropTypes from '@/shape/content-summary'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Grid from '@/component/Primitive/Grid'
import ContentSummary from '@/component/Primitive/ContentSummary'
import Type from '@/component/Primitive/Type'

import styles from '../theme/BBFCContentSummaryGrid.module.scss'

const ContentSummaryGrid = ({ items, title, subtitle, designV2 }) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classNames(
        styles.ContentSummaryGrid,
        isDark && styles.isDark,
        designV2 && styles.designV2
      )}
    >
      {title && (
        <Type
          className={classNames(
            styles.Title,
            designV2 && 'article-widget-heading'
          )}
          color={!isDark ? 'teal' : 'white'}
          as="h2"
          size="display1"
        >
          {title}
        </Type>
      )}
      {subtitle && (
        <Type className={classNames(styles.Subtitle)} as="p" size="base-large">
          {subtitle}
        </Type>
      )}
      <Grid gutter="small">
        {items &&
          items.map((item, i) => (
            <Grid.Item width={[1, 1, 1 / 3]} key={`ContentSummary-${i}`}>
              <ContentSummary designV2={designV2} link={item.link} {...item} />
            </Grid.Item>
          ))}
      </Grid>
    </div>
  )
}

ContentSummaryGrid.propTypes = {
  items: arrayOf(shape(contentSummaryPropTypes)),
  title: string,
  subtitle: string,
  designV2: bool
}

export default ContentSummaryGrid
