import React from 'react'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import AgeRating from '@/component/Primitive/AgeRating'
import RatingColor from '../../RatingColor'

import styles from './Classification.module.scss'
import { string, arrayOf, bool } from 'prop-types'

const Classification = ({ rating, issues, isOur }) => {
  const isDark = useDarkContext()
  return (
    <RatingColor
      rating={rating}
      property="border"
      as="div"
      className={classNames(styles.AnswerBox, isDark && styles.isDark)}
    >
      <Type size="display1">{isOur ? 'Our' : 'Your'} classification</Type>
      <AgeRating rating={rating} className={styles.Rating} />
      <div className={styles.IssueWrapper}>
        <Type size="base-large" bold>
          Issues spotted:
        </Type>
        <RatingColor
          rating={rating}
          as="ul"
          property="color"
          className={styles.List}
        >
          {issues.map((issue, i) => (
            <li className={styles.ListItem} key={`Issue-${issue}-${i}`}>
              {issue}
            </li>
          ))}
        </RatingColor>
      </div>
    </RatingColor>
  )
}

export default Classification

Classification.propTypes = {
  rating: string,
  issues: arrayOf(string),
  isOur: bool
}
