import React from 'react'
import { string, shape, arrayOf, bool } from 'prop-types'
import classNames from 'classnames'

import SmartLink from '@/component/Primitive/SmartLink'
import Type from '@/component/Primitive/Type'

import styles from '../theme/CBBFCArticleHeader.module.scss'

const ArticleHeader = ({ article, sections, isDark }) => {
  if (!article) return null
  const { headline } = article

  return (
    <div className={classNames(styles.ArticleHeader, isDark && styles.isDark)}>
      {sections &&
        sections.map((breadCrumb, i) => (
          <Type
            as="span"
            size="base"
            className={styles.BreadCrumb}
            key={`Breadcrumb-${breadCrumb.name}-${i}`}
            bold
          >
            <SmartLink to="/section" as={breadCrumb.fullUrlPath}>
              {breadCrumb.name}
            </SmartLink>
          </Type>
        ))}
      <Type className={styles.Title} color="teal" as="h2" size="display2">
        {headline}
      </Type>
    </div>
  )
}

ArticleHeader.propTypes = {
  article: shape({
    headline: string
  }),
  sections: arrayOf(
    shape({
      fullUrlPath: string,
      name: string
    })
  ),
  isDark: bool
}

export default ArticleHeader
