import React from 'react'
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'
import { NextSeo } from 'next-seo'

const StandardMeta = ({
  title,
  description,
  seoTitle,
  seoDescription,
  canonicalUrl,
  customTitle,
  images
}) => {
  const config = {
    title,
    titleTemplate: customTitle && '%s',
    description,
    canonical: canonicalUrl,
    openGraph: {
      title: seoTitle || title,
      description: seoDescription || description,
      url: canonicalUrl,
      images
    }
  }
  return <NextSeo {...config} />
}

StandardMeta.propTypes = {
  title: string.isRequired,
  description: string,
  seoTitle: string,
  seoDescription: string,
  canonicalUrl: string.isRequired,
  customTitle: bool,
  images: arrayOf(
    shape({
      url: string.isRequired,
      width: oneOfType([string, number]),
      height: oneOfType([string, number]),
      alt: string
    })
  )
}

export default StandardMeta
