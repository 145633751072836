import React from 'react'
import styles from './CarouselItem.module.scss'
import Icon from '@/component/Primitive/Icon'
import classNames from 'classnames'
import ReleasePoster from '../../ReleasePoster'

import ContentAdvice from '../../../Primitive/ContentAdvice/ContentAdvice'
import { string, any, array, bool } from 'prop-types'
import { paragraphShorten } from '@/lib/paragraph-shorten'
import { useDarkContext } from '@/component/Context/DarkContext'

const CarouselItem = (props) => {
  const {
    rating,
    mediaTitle,
    shortFormInsight,
    summary,
    img,
    visualAdviceOverview,
    url,
    singleItem,
    small
  } = props
  const isDark = useDarkContext()
  const formattedRating = rating ? rating.toLowerCase() : ''

  const shortenSummary = paragraphShorten(summary)
  const formattedShortFormInsight = shortFormInsight
    ? shortFormInsight[0].toUpperCase() + shortFormInsight.slice(1)
    : ''

  return (
    <a
      href={url}
      className={classNames(
        styles.Container,
        singleItem && styles.SingleItemContainer,
        isDark && styles.dark
      )}
    >
      <div className={styles.DesktopPoster}>
        <ReleasePoster image={img} classification={rating} />
      </div>
      <div
        className={classNames(
          styles.Content,
          singleItem && styles.SingleItemContent
        )}
      >
        <div className={styles.TopContent}>
          <div className={styles.RatingIconWrap}>
            <Icon
              className={styles.RatingIcon}
              type={`rating-${formattedRating}`}
              a11yText={`rating-${formattedRating}`}
            />
          </div>
          <h3 className={styles.MediaTitle}>{mediaTitle}</h3>
        </div>
        <div
          className={classNames(
            styles[`Rating-${formattedRating}`],
            styles.ShortFormInsight
          )}
        >
          {formattedShortFormInsight}
        </div>
        <div
          className={classNames(
            styles.SummaryWrap,
            !summary && styles.NoPadding
          )}
        >
          <div className={styles.MobilePoster}>
            <ReleasePoster image={img} classification={rating} />
          </div>

          {summary && (
            <div>
              <div className={styles.DesktopSummary}>{summary}</div>
              <div className={styles.MobileSummary}>
                {shortenSummary}
                <p className={styles.ReadMore}>Read more</p>
              </div>
            </div>
          )}
        </div>
        {visualAdviceOverview &&
          visualAdviceOverview.length > 0 &&
          visualAdviceOverview.find(
            (item) => item.strength && item.strength > 0
          ) && (
            <>
              <h4 className={styles.VisualAdviceOverviewTitle}>
                Content Advice
              </h4>
              <ContentAdvice
                data={visualAdviceOverview}
                rating={formattedRating}
                small={small}
              />
            </>
          )}
      </div>
    </a>
  )
}
CarouselItem.propTypes = {
  rating: string,
  mediaTitle: string,
  shortFormInsight: string,
  summary: string,
  img: any,
  visualAdviceOverview: array,
  url: string,
  singleItem: bool,
  small: bool
}

export default CarouselItem
