import React from 'react'
import imagePropTypes from '@/shape/image'
import classNames from 'classnames'
import { string, shape, bool } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Expander from '@/component/Primitive/Expander'

import styles from './Bio.module.scss'

const Bio = ({ name, title, content, img, designV2 }) => {
  const isDark = useDarkContext()

  return (
    <div className={classNames(styles.Bio, isDark && styles.isDark)}>
      <ResponsiveMedia ratio={1}>
        <ResponsiveImage {...img} alt={name} />
      </ResponsiveMedia>
      <div className={styles.BioWrapper}>
        {designV2 ? (
          <>
            <h3 className={styles.NameV2}>{name}</h3>
            <p className={styles.TitleV2}>{title}</p>
          </>
        ) : (
          <>
            {' '}
            <Type as="h3" size="base" bold>
              {name}
            </Type>
            <Type as="p" size="base">
              {title}
            </Type>
          </>
        )}
      </div>
      {content && (
        <Expander
          title="Read bio"
          classNames={{ title: styles.Title, icon: designV2 && styles.IconV2 }}
        >
          <div
            className={classNames(
              styles.ExpanderCopy,
              designV2 && styles.ExpanderCopyV2
            )}
          >
            {content}
          </div>
        </Expander>
      )}
    </div>
  )
}

export default Bio

Bio.propTypes = {
  name: string.isRequired,
  title: string,
  content: string.isRequired,
  img: shape(imagePropTypes).isRequired,
  url: string,
  designV2: bool
}
