import News from '@/component/Common/News'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import loadMoreFromLists from '@/lib/load-more-from-lists'
import createLoadMoreQuery from '@/query/widgets/bbfc/news-load-more'

const NewsWidget = ({
  title,
  ctaLabel,
  ctaLink,
  showFeatured,
  lists,
  loadMore,
  loadMoreMethod,
  initialPageSize,
  limit,
  loadMorePageSize
}) => {
  const [articles, setArticles] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)

  const handleLoadMore = async () => {
    try {
      setLoadingMore(true)

      const pageSize = loadMore
        ? page === 1
          ? initialPageSize
          : loadMorePageSize
        : limit
      const data = await loadMoreFromLists(
        createLoadMoreQuery(),
        lists,
        page,
        pageSize
      )

      setArticles((prevArticles) => [...prevArticles, ...data])
      setPage((prevPage) => prevPage + 1)
      setHasMore(loadMore && data.length === pageSize)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingMore(false)
    }
  }

  // Load initial articles
  useEffect(() => {
    handleLoadMore()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If we have no articles, then we don't want to render the News component
  if (articles.length === 0) {
    return null
  }

  return (
    <News
      title={title}
      ctaLabel={ctaLabel}
      ctaLink={ctaLink}
      showFeatured={showFeatured}
      articles={(articles || []).map((article, i) => ({
        id: article._id,
        title: article.headline,
        shortDescription: article.sell || article.standfirst,
        ctaLink: article.fullUrlPath || '',
        date: new Date(article.displayDate),
        isFeaturedNew: i === 0,
        readTimeInMins: article.readTimeInMins
      }))}
      loadMore={hasMore && loadMore}
      loadMoreMethod={loadMoreMethod}
      loadingMore={loadingMore}
      onLoadMore={handleLoadMore}
    />
  )
}

NewsWidget.propTypes = {
  title: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  showFeatured: PropTypes.bool,
  lists: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  initialPageSize: PropTypes.number,
  limit: PropTypes.number,
  loadMorePageSize: PropTypes.number
}

export default NewsWidget
