import React from 'react'
import styles from './PageSubNav.module.scss'
import PropTypes from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const PageSubNav = (props) => {
  const { title, links } = props
  const isDark = useDarkContext()
  if (!links || links.length <= 0) {
    console.warn('[PageSubNavWidget] Skipping render: No links to display')
    return null
  }
  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.Content}>
        {title && <div className={styles.Title}>{title}</div>}
        {links && links.length > 0 && (
          <div className={styles.LinkList}>
            {links.map((item, index) => (
              <a key={index} className={styles.LinkItem} href={item.ctaLink}>
                {item.ctaLabel}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
PageSubNav.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf({
    ctaLabel: PropTypes.string,
    ctaLink: PropTypes.string
  })
}
export default PageSubNav
