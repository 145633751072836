import React from 'react'
import contentSummaryPropTypes from '@/shape/content-summary'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import SmartLink from '@/component/Primitive/SmartLink'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Surround from '@/component/Primitive/Surround'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import Type from '@/component/Primitive/Type'

import styles from '../theme/BBFCContentSummary.module.scss'

const ContentSummary = ({ link, img, heading, body, designV2 }) => {
  const Wrapper = link ? SmartLink : 'div'
  const { alt, src, srcSet } = img
  const isDark = useDarkContext()
  return (
    <Wrapper
      to="/article"
      as={link}
      className={classnames(
        styles.ContentSummary,
        isDark && styles.isDark,
        designV2 && styles.designV2
      )}
    >
      <Surround rounded>
        <ResponsiveMedia ratio={9 / 16}>
          <ResponsiveImage alt={alt} src={src} srcSet={srcSet} />
        </ResponsiveMedia>
      </Surround>
      <Type as="h3" size="title" className={classnames(styles.Heading)}>
        {heading}
      </Type>
      <Type as="p" size="base-large" className={classnames(styles.Body)}>
        {body}
      </Type>
    </Wrapper>
  )
}

ContentSummary.propTypes = contentSummaryPropTypes

export default ContentSummary
