import React, { useState, useRef } from 'react'
import { arrayOf, string, array } from 'prop-types'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Rating from './component/Rating'
import Answer from './component/Answer'

import styles from './RateATrailer.module.scss'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const RateATrailer = ({
  enabledClassifications,
  videoProvider,
  videoId,
  correctThemes,
  correctRating,
  availableOptions,
  explanation
}) => {
  const isDark = useDarkContext()
  const [STATE, setState] = useState('RATING') // States: RATING, ANSWER
  const [chosenThemes, setchosenThemes] = useState([])
  const [chosenRating, setchosenRating] = useState(undefined)
  const scrollRef = useRef(null)

  const updatedThemes = correctThemes.filter((theme) =>
    availableOptions.includes(theme)
  )

  const handleThemeSelect = (selected) => {
    let tmp = chosenThemes.slice(0)
    if (tmp.length > 0 && tmp.includes(selected))
      tmp = tmp.filter((option) => option !== selected)
    else tmp.push(selected)
    setchosenThemes(tmp)
  }

  const handleRatingSelect = (selected) => {
    setchosenRating(selected)
  }

  const handleSubmit = () => {
    setState('ANSWER')
    scrollToRef(scrollRef)
  }

  return (
    <div
      ref={scrollRef}
      className={classNames(styles.RateATrailer, isDark && styles.isDark)}
    >
      {STATE === 'RATING' && (
        <Rating
          enabledClassifications={enabledClassifications}
          videoProvider={videoProvider}
          videoId={videoId}
          availableOptions={availableOptions}
          onThemeSelect={handleThemeSelect}
          onRatingSelect={handleRatingSelect}
          chosenThemes={chosenThemes}
          chosenRating={chosenRating}
          onSubmit={handleSubmit}
        />
      )}
      {STATE === 'ANSWER' && (
        <Answer
          explanation={explanation}
          correctThemes={updatedThemes}
          correctRating={correctRating}
          chosenThemes={chosenThemes}
          chosenRating={chosenRating}
        />
      )}
    </div>
  )
}

RateATrailer.propTypes = {
  enabledClassifications: array,
  availableOptions: array,
  videoProvider: string,
  videoId: string,
  correctThemes: arrayOf(string),
  correctRating: string,
  explanation: string
}

export default RateATrailer
