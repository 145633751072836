const schemata = require('schemata')
const validity = require('validity')
const validator = require('email-validator')
const required = require('validity-required')
const oneOf = require('validity-required-options')

const customEmailValidator = validity.createValidatorAllowingFailureMessageOverride(
  validity.booleanToCallback(validator.validate),
  'Must be a valid email address'
)

const createSchema = (questions) => {
  const properties =
    questions &&
    questions.reduce((schema, question, i) => {
      const questionNumber = i + 1

      schema[`question-${questionNumber}`] = {}
      schema[`question-${questionNumber}`].validators = []

      if (question.required) {
        schema[`question-${questionNumber}`].validators.push(
          required.setFailureMessage('Response required')
        )
      }

      if (question.inputType === 'email') {
        schema[`question-${questionNumber}`].validators.push(
          customEmailValidator
        )
      }

      if (question.inputType === 'radio') {
        const hasResponses = question.responses && question.responses.length > 0
        const allowedResponses =
          hasResponses && question.responses.map((r) => r.response)

        schema[`question-${questionNumber}`].validators.push(
          oneOf(allowedResponses)
        )
      }

      return schema
    }, {})

  const formResponseSchema = {}

  formResponseSchema.name = 'Form Schema'
  formResponseSchema.properties = properties

  const schema = schemata(formResponseSchema)

  return schema
}

module.exports = createSchema
