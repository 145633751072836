import PropTypes from 'prop-types'
import React from 'react'
import YouTubeEmbed from '@/component/Primitive/YouTubeEmbed'
import VimeoEmbed from '@/component/Primitive/VimeoEmbed'
import Trailer from '@/component/Common/Trailer'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import classNames from 'classnames'

const VideoWidgetWrap = ({ children, designV2, padding }) => {
  if (designV2 || padding) {
    return (
      <div
        className={classNames(
          designV2 && 'video-widget--V2',
          padding && 'vertical-padding--section horizontal-padding--section'
        )}
      >
        {children}
      </div>
    )
  }
  return <>{children}</>
}

const VideoWidget = (props) => {
  const { videoId, videoProvider, designV2, padding } = props
  return (
    <VideoWidgetWrap designV2={designV2} padding={padding}>
      {videoProvider === 'MovieXchange' && (
        <Trailer
          hlsStreamUrl={`https://film-cdn.moviexchange.com/api/cdn/release/${videoId}/media/TrailerVideo?width=0&height=0`}
        />
      )}
      {['YouTube', 'Vimeo'].includes(videoProvider) && (
        <ResponsiveMedia ratio={9 / 16}>
          {videoProvider === 'YouTube' && <YouTubeEmbed videoId={videoId} />}
          {videoProvider === 'Vimeo' && <VimeoEmbed videoId={videoId} />}
        </ResponsiveMedia>
      )}
    </VideoWidgetWrap>
  )
}

VideoWidgetWrap.propTypes = {
  designV2: PropTypes.bool,
  padding: PropTypes.bool,
  children: PropTypes.node
}

VideoWidget.propTypes = {
  videoId: PropTypes.string,
  videoProvider: PropTypes.string,
  designV2: PropTypes.bool,
  padding: PropTypes.bool
}

export default VideoWidget
