import React from 'react'
import styles from './Quote.module.scss'
import Prose from '@/component/Primitive/Prose'
import DoubleQuote from '@/asset/img/redesign24/doulbleQuote.svg'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import { string } from 'prop-types'

const Quote = ({ html }) => {
  const isDark = useDarkContext()

  return (
    <div className={styles.Container}>
      <img
        className={classNames(styles.DoubleQuote, styles.OpenQuote)}
        alt="Logo"
        src={DoubleQuote}
      />
      <div className={classNames(styles.Content, isDark && styles.DarkContent)}>
        <Prose className={styles.QuoteProse} html={html} />
      </div>

      <img
        className={classNames(styles.DoubleQuote, styles.CloseQuote)}
        alt="Logo"
        src={DoubleQuote}
      />
    </div>
  )
}

Quote.propTypes = {
  html: string
}

export default Quote
