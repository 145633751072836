import React from 'react'
import { array } from 'prop-types'
import { RatingContextProvider } from '@/component/Context/RatingContext'

import SplitPanel from '@/component/Primitive/SplitPanel'
import Type from '@/component/Primitive/Type'
import Container from '@/component/Primitive/Container'

const BBFCHeroArticle = (props) => {
  const { articles } = props
  const firstArticle = articles && articles[0]
  const secondArticle = articles && articles[1]
  if (!firstArticle) return null
  const firstImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_1000 &&
    firstArticle.images.hero_5x4_1000[0]
  const firstImageUrl = firstImage && firstImage.url
  const firstImageAlt = firstImage && firstImage.alt
  const firstImageTablet =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_500 &&
    firstArticle.images.hero_5x4_500[0]
  const firstImageTabletUrl = firstImageTablet && firstImageTablet.url
  const firstImageTabletAlt = firstImageTablet && firstImageTablet.alt
  const firstImageMobile =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_16x9_550 &&
    firstArticle.images.hero_16x9_550[0]
  const firstImageMobileUrl = firstImageMobile && firstImageMobile.url
  const firstImageMobileAlt = firstImageMobile && firstImageMobile.alt
  const firstLink = firstArticle && firstArticle.fullUrlPath
  const secondImage =
    secondArticle &&
    secondArticle.images &&
    secondArticle.images.hero_16x9_928 &&
    secondArticle.images.hero_16x9_928[0]
  const secondImageUrl = secondImage && secondImage.url
  const secondImageAlt = secondImage && secondImage.alt
  const secondImageMobile =
    secondArticle &&
    secondArticle.images &&
    secondArticle.images.hero_16x9_550 &&
    secondArticle.images.hero_16x9_550[0]
  const secondImageMobileUrl = secondImageMobile && secondImageMobile.url
  const secondImageMobileAlt = secondImageMobile && secondImageMobile.alt
  const secondLink = secondArticle && secondArticle.fullUrlPath
  return (
    <>
      <RatingContextProvider rating={firstArticle.contentRating}>
        <SplitPanel
          displayDensity="spacious"
          img={{ src: firstImageUrl, alt: firstImageAlt || '' }}
          tabletImg={{
            src: firstImageTabletUrl,
            alt: firstImageTabletAlt || ''
          }}
          mobileImg={{
            src: firstImageMobileUrl,
            alt: firstImageMobileAlt || ''
          }}
          link={firstLink}
          underlayed={secondArticle}
        >
          <Type size="display3" as="h2">
            {firstArticle && firstArticle.headline}
          </Type>
          <Type size="base-large" as="p">
            {firstArticle && firstArticle.sell}
          </Type>
        </SplitPanel>
      </RatingContextProvider>
      {secondArticle && (
        <Container gutter center size="full">
          <SplitPanel
            img={{ src: secondImageUrl, alt: secondImageAlt || '' }}
            tabletImg={{
              src: secondImageMobileUrl,
              alt: secondImageMobileAlt || ''
            }}
            mobileImg={{
              src: secondImageMobileUrl,
              alt: secondImageMobileAlt || ''
            }}
            link={secondLink}
            shadow
            rounded
            overlayed
          >
            <Type as="h2" size="title">
              {secondArticle && secondArticle.headline}
            </Type>
            <Type as="p" size="base-large">
              {secondArticle && secondArticle.sell}
            </Type>
          </SplitPanel>
        </Container>
      )}
    </>
  )
}

BBFCHeroArticle.propTypes = {
  articles: array
}

export default BBFCHeroArticle
