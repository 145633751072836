import React, { useEffect, useState } from 'react'
import styles from './ProgressScroller.module.scss'
import useScrollPosition from '@/component/Widgets/ProgressScroller/useScrollPosition'

const ProgressScroller = () => {
  const [pageHeight, setPageHeight] = useState(0)
  const scrollValue = useScrollPosition()

  useEffect(() => {
    setPageHeight(document.documentElement.offsetHeight - window.innerHeight)
  }, [])

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarBar}
          style={{ height: `${(scrollValue / pageHeight) * 100}%` }}
        />
      </div>
    </div>
  )
}

export default ProgressScroller
