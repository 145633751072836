import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on List {
    dedupe
    limit
    lists
    articles {
      _id
      headline
      shortHeadline
      images {
        hero_5x4_1000 {
          ${imageProperties}
        }
        hero_16x9_712 {
          ${imageProperties}
        }
        hero_16x9_928 {
          ${imageProperties}
        }
        thumbnail_3x2_438 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
