import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import Prose from '@/component/Primitive/Prose'
import { Accordion } from '../Release/components/Accordion/Accordion'
import styles from './ListicleListItem.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'
import classNames from 'classnames'

const getImageUrlForItem = (item) => {
  if (item.images && item.images._573 && item.images._573[0]) {
    return item.images._573[0].url
  }

  return null
}

const ListicleListItem = ({ item }) => {
  const isDark = useDarkContext()
  const [showContentAdvice, setShowContentAdvice] = useState(false)

  const imageUrl = getImageUrlForItem(item)

  // If there is no image, we don't want to render the item
  if (!imageUrl) {
    return null
  }

  const renderContentAdvice = () => {
    if (item.contentAdvice) {
      return (
        <Prose className={styles.ContentAdvice} html={item.contentAdvice} />
      )
    }

    return null
  }

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      <div className={styles.ItemWrap}>
        <div className={styles.ImageWrap}>
          <ResponsiveMedia ratio={3 / 2}>
            <ResponsiveImage src={imageUrl} alt={item.title} />
          </ResponsiveMedia>
        </div>
        <div className={styles.ItemContentWrap}>
          <div className={styles.TopContentWrap}>
            <div className={styles.TopContent}>
              <h3 className={styles.MediaTitle}>{item.title}</h3>
            </div>
            {item.description && (
              <Prose className={styles.SummaryWrap} html={item.description} />
            )}
          </div>

          {item.contentAdvice && (
            <Accordion title="Content Advice" onChange={setShowContentAdvice} />
          )}
        </div>
      </div>
      {showContentAdvice && (
        <div className={styles.ContentAdviceWrap}>{renderContentAdvice()}</div>
      )}
    </div>
  )
}

ListicleListItem.propTypes = {
  item: PropTypes.shape({
    images: PropTypes.shape({
      _573: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string
        })
      )
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    contentAdvice: PropTypes.string
  })
}

export default ListicleListItem
