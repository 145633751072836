import React from 'react'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import propTypes from 'prop-types'

import Icon from '@/component/Primitive/Icon'
import SmartLink from '@/component/Primitive/SmartLink'
import Type from '@/component/Primitive/Type'

import styles from './Pagination.module.scss'
import calculatePages from './lib/calculate-pages'

const Pagination = ({
  pages = 1,
  currentPage = 1,
  handlePageButtonClick,
  buttonsShown,
  designV2
}) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classNames(
        styles.Pagination,
        isDark && styles.isDark,
        designV2 && styles.designV2
      )}
    >
      <SmartLink onClick={() => handlePageButtonClick(currentPage - 1)}>
        <Icon
          type="arrow-left"
          height={24}
          width={24}
          className={styles.Icon}
          a11yText="Left Arrow"
          vAlign="baseline"
        />
      </SmartLink>
      <div className={styles.PageWrapper}>
        {calculatePages(pages, currentPage, buttonsShown).map((item, index) => (
          <SmartLink
            key={`Page-${index}`}
            className={classNames(
              currentPage === item && styles.SelectedPage,
              styles.Page
            )}
            onClick={() => handlePageButtonClick(item)}
          >
            <Type size="base-large">{item}</Type>
          </SmartLink>
        ))}
      </div>
      <SmartLink onClick={() => handlePageButtonClick(currentPage + 1)}>
        <Icon
          type="arrow-right"
          height={24}
          width={24}
          className={styles.Icon}
          a11yText="Right Arrow"
        />
      </SmartLink>
    </div>
  )
}

Pagination.propTypes = {
  pages: propTypes.number,
  currentPage: propTypes.number,
  handlePageButtonClick: propTypes.func.isRequired,
  buttonsShown: propTypes.number,
  designV2: propTypes.bool
}

export default Pagination
