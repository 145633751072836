import React from 'react'
import classnames from 'classnames'
import imagePropTypes from '@/shape/image'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'

import styles from './Slide.module.scss'

const Slide = ({ alt, src }) => {
  const isDark = useDarkContext()
  return (
    <div className={classnames(styles.Slide, isDark && styles.isDark)}>
      <div
        className={styles.Image}
        style={{ backgroundImage: `url(${src})` }}
      />
      <div className={styles.AltWrapper}>
        <Type as="p" size="base" className={styles.Alt}>
          {alt || ''}
        </Type>
      </div>
    </div>
  )
}

export default Slide

Slide.propTypes = imagePropTypes
