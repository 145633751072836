import React from 'react'
import downloadsListPropTypes from '@/shape/downloads-list'

import ListItem from './component/ListItem'
import Type from '@/component/Primitive/Type'

import styles from './FilmInformationList.module.scss'
import List from '@/component/Primitive/List'
import { useDarkContext } from '@/component/Context/DarkContext'

const FilmInformationList = ({ releaseDetails }) => {
  const isDark = useDarkContext()
  if (!releaseDetails || !releaseDetails.length) return null
  return (
    <>
      <Type
        as="h3"
        size="display0"
        color={isDark ? 'white' : 'teal'}
        className={styles.Title}
      >
        Film Information
      </Type>
      <List unstyled>
        {releaseDetails &&
          releaseDetails.map((detail, i) => {
            return (
              <ListItem
                category={detail.category}
                value={detail.value}
                key={`Release-${detail.title}-${i}`}
              />
            )
          })}
      </List>
    </>
  )
}

FilmInformationList.propTypes = downloadsListPropTypes

export default FilmInformationList
