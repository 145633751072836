import React from 'react'
import { node, bool } from 'prop-types'
import classNames from 'classnames'

import Surround from '@/component/Primitive/Surround'
import Container from '@/component/Primitive/Container'
import Icon from '@/component/Primitive/Icon'

import styles from '../theme/CBBFCColumn.module.scss'

const CBBFCColumn = ({ childrenWithProps, isDark }) => {
  return (
    <div className={classNames(styles.ColumnWrapper, 'column')}>
      <div className={styles.Line} style={{ left: `25%` }}>
        <Icon type="wavy-line" className={styles.Line} />
      </div>
      <div className={styles.Line} style={{ left: `75%` }}>
        <Icon type="wavy-line" className={styles.Line} />
      </div>
      <Surround isDark={isDark} rounded solid shadow className={styles.Column}>
        <Container gutter>{childrenWithProps}</Container>
      </Surround>
    </div>
  )
}

CBBFCColumn.propTypes = {
  childrenWithProps: node,
  isDark: bool
}

export default CBBFCColumn
