import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'
import classNames from 'classnames'

import ProgressBar from '@/component/Primitive/ProgressBar'
import Type from '@/component/Primitive/Type'

import styles from './Results.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const Results = ({ questions, description, resultsTitle }) => {
  const isDark = useDarkContext()

  return (
    <div className={classNames(styles.Results, isDark && styles.isDark)}>
      <Type as="h4" size="display1" className={styles.Title}>
        {resultsTitle || ''}
      </Type>
      <Type size="base-large" as="p" className={styles.Description}>
        {description || ''}
      </Type>
      {questions &&
        questions.map((question, i) => (
          <Result
            question={question.question}
            options={question.options}
            key={`ResultQuestion-${i}-${question.question}`}
          />
        ))}
    </div>
  )
}

Results.propTypes = {
  description: string,
  resultsTitle: string,
  questions: arrayOf(
    shape({
      question: string,
      options: arrayOf(
        shape({
          option: string,
          timesVoted: number
        })
      )
    })
  )
}

const Result = ({ question, options }) => {
  const totalTimesVoted =
    options && options.reduce((total, option) => total + option.timesVoted, 0)
  return (
    <div className={styles.Result}>
      <Type size="base-large" className={styles.QuestionText}>
        {question}
      </Type>
      {options &&
        options.map((option, i) => {
          const percentage =
            ((option.timesVoted / totalTimesVoted) * 100).toFixed(0) + '%'
          return (
            <div
              className={styles.Wrapper}
              key={`OptionResult-${i}-${option.option}`}
            >
              <Type size="base-large" className={styles.Option}>
                {option.option || 'No Response'}
              </Type>
              <div className={styles.ProgressBarWrapper}>
                <Type size="base-large" className={styles.Percentage}>
                  {percentage}
                </Type>
                <ProgressBar max={totalTimesVoted} value={option.timesVoted} />
              </div>
            </div>
          )
        })}
    </div>
  )
}

Result.propTypes = {
  question: string,
  options: arrayOf(
    shape({
      option: string,
      timesVoted: number
    })
  )
}

export default Results
