const createQuery = () => `
  query NewsLoadMore($lists: [String], $page: Int, $limit: Int) {
    loadMore(lists: $lists, page: $page, limit: $limit) {
      articles {
        _id
        headline
        shortHeadline
        fullUrlPath
        displayDate
        readTimeInMins
        sell
        standfirst
      }
    }
  }
`

export default createQuery
