import React from 'react'
import styles from './ListicleHero.module.scss'
import { shape, string, arrayOf } from 'prop-types'

const ListicleHero = (props) => {
  const { title, ListicleHeroImages } = props
  const bgImage = ListicleHeroImages.hero_16x9_1000[0].url
  const imgAlt = ListicleHeroImages.hero_16x9_1000[0].alt
  const pageName = props.section.name

  return (
    <div
      className={styles.Container}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <span role="img" aria-label={imgAlt || title} />

      <div className={styles.NavContentWrap}>
        <div>
          <a href="/">Home / </a>

          <span>
            <strong className={styles.DesktopLink}>{pageName}</strong>
          </span>
        </div>
      </div>
      <div className={styles.ContentWrap}>
        <div className={styles.Content}>
          <div className={styles.Headline}>{title}</div>
        </div>
      </div>
    </div>
  )
}
ListicleHero.propTypes = {
  section: shape({
    name: string
  }),
  ListicleHeroImages: shape({
    hero_16x9_1000: arrayOf(
      shape({
        url: string
      })
    )
  }),
  title: string
}
export default ListicleHero
