import React from 'react'
import PropTypes from 'prop-types'

import TextControl from '@/component/Primitive/TextControl'
import { useDarkContext } from '@/component/Context/DarkContext'

const StandardControlField = ({
  showRequired = true,
  required,
  error,
  label,
  responses,
  handleOnChange,
  type,
  wordLimit,
  name,
  ...other
}) => {
  const isTextArea = type === 'textarea'
  const isDark = useDarkContext()
  return (
    <>
      <TextControl
        name={name}
        multiLine={isTextArea}
        rows={5}
        onChange={(e) => handleOnChange(e, wordLimit)}
      />
      {wordLimit && wordLimit > 0 && (
        <span style={{ color: isDark ? 'white' : 'inherit' }}>
          Word limit of {wordLimit}
        </span>
      )}
    </>
  )
}

StandardControlField.defaultProps = {}

StandardControlField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  wordLimit: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  responses: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default StandardControlField
