import { arrayOf, string, shape } from 'prop-types'

export default {
  downloads: arrayOf(
    shape({
      title: string,
      url: string,
      fileType: string,
      fileSize: string
    })
  ),
  title: string
}
