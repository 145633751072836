import React from 'react'
import MediaList from '../../Common/MediaList'
import MediaListHeader from '../../Common/MediaListHeader'
import Pagination from '../../Common/Pagination'
import { array, bool, object, number } from 'prop-types'
import { withRouter } from 'next/router'

const calculatePages = (total, perPage) => {
  return Math.ceil(total / perPage) || 1
}

const calculatePage = (page, pages) => {
  if (page < 1) page = 1
  if (page > pages) page = pages
  return page
}
const articlesPerPage = 6
const PaginatedArticleListWidget = ({
  showImages,
  heroFirstItem,
  totalArticles,
  articles,
  pageData,
  router,
  section,
  designV2
}) => {
  const onHandleButtonClick = (setPage) => {
    const pages = calculatePages(totalArticles, articlesPerPage)
    const page = calculatePage(parseInt(setPage), pages) || 1
    router.push(`/section?page=${page}`, `${section.fullUrlPath}?page=${page}`)
  }

  const pages = calculatePages(totalArticles, articlesPerPage)
  const pageParam = pageData && pageData.params && pageData.params.page
  const page = calculatePage(parseInt(pageParam), pages) || 1

  const remainingArticles = articles && articles.slice(1)
  const firstArticle =
    articles && articles.slice(0, 1) && articles.slice(0, 1)[0]
  const filteredArticles = heroFirstItem ? remainingArticles : articles

  const firstArticleImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_16x9_1026 &&
    firstArticle.images.hero_16x9_1026[0]
  const img = {
    src: firstArticleImage && firstArticleImage.url,
    alt: (firstArticleImage && firstArticleImage.alt) || ''
  }
  return (
    <>
      {heroFirstItem && firstArticle && (
        <MediaListHeader
          title={firstArticle.headline}
          description={firstArticle.sell}
          img={img}
          url={firstArticle.fullUrlPath}
          designV2={designV2}
        />
      )}
      <MediaList
        items={filteredArticles}
        numberOfTotalItems={totalArticles}
        showImages={showImages}
        articlesPerPage={articlesPerPage}
        designV2={designV2}
      />
      <Pagination
        pages={pages}
        currentPage={page}
        handlePageButtonClick={onHandleButtonClick}
        buttonsShown={9}
        designV2={designV2}
      />
    </>
  )
}

PaginatedArticleListWidget.propTypes = {
  router: object,
  articles: array,
  heroFirstItem: bool,
  designV2: bool,
  showImages: bool,
  section: object,
  pageData: object,
  totalArticles: number
}

export default withRouter(PaginatedArticleListWidget)
