import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NewsListingV2 from '@/component/BBFC/NewsListingV2'
import loadMoreFromLists from '@/lib/load-more-from-lists'
import createLoadMoreQuery from '@/query/widgets/bbfc/news-listing-v2-load-more'

const NewsListingV2Widget = ({
  title,
  description,
  searchPlaceholder,
  showFeatured,
  lists,
  loadMore,
  loadMoreMethod,
  initialPageSize,
  limit,
  loadMorePageSize
}) => {
  const [articles, setArticles] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [lastSearchTerm, setLastSearchTerm] = useState('')

  const fetchArticles = async (page, pageSize, searchTerm) => {
    return await loadMoreFromLists(
      createLoadMoreQuery(),
      lists,
      page,
      pageSize,
      searchTerm
    )
  }

  const handleLoadMore = async () => {
    try {
      setLoadingMore(true)

      const pageSize = loadMore
        ? page === 1
          ? initialPageSize
          : loadMorePageSize
        : limit
      const data = await fetchArticles(page, pageSize, lastSearchTerm)

      setArticles((prevArticles) => [...prevArticles, ...data])
      setPage((prevPage) => prevPage + 1)
      setHasMore(loadMore && data.length === pageSize)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingMore(false)
    }
  }

  const handleSearchChange = async (value) => {
    try {
      const data = await fetchArticles(1, initialPageSize, value)
      setArticles(data)
      setPage(2)
      setHasMore(loadMore && data.length === initialPageSize)
      setLastSearchTerm(value)
    } catch (error) {
      console.error(error)
    }
  }

  // Load initial articles
  useEffect(() => {
    handleLoadMore()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NewsListingV2
      title={title}
      description={description}
      searchPlaceholder={searchPlaceholder}
      showFeatured={showFeatured}
      searchTerm={lastSearchTerm}
      articles={(articles || []).map((article, i) => ({
        id: article._id,
        title: article.headline,
        shortDescription: article.sell || article.standfirst,
        ctaLink: article.fullUrlPath || '',
        date: new Date(article.displayDate),
        isFeaturedNew: i === 0,
        readTimeInMins: article.readTimeInMins
      }))}
      loadMore={hasMore && loadMore}
      loadMoreMethod={loadMoreMethod}
      loadingMore={loadingMore}
      onLoadMore={handleLoadMore}
      onSearchChange={handleSearchChange}
    />
  )
}

NewsListingV2Widget.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  showFeatured: PropTypes.bool,
  lists: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
  loadMoreMethod: PropTypes.string,
  initialPageSize: PropTypes.number,
  limit: PropTypes.number,
  loadMorePageSize: PropTypes.number
}

export default NewsListingV2Widget
