import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './AboutThisFilm.module.scss'
import CarouselItem from '../LatestReleaseCarousel/Carouseltem/CarouselItem'

const AboutThisFilm = ({ release }) => {
  const releaseImage =
    release.images && release.images._751 && release.images._751.url
  const visualAdviceOverview =
    release.visualAdviceLayer && release.visualAdviceLayer.overview

  const data = {
    rating: release.classification,
    mediaTitle: release.title,
    summary: release.synopsis || '',
    shortFormInsight: release.shortFormInsight || '',
    img: releaseImage && {
      src: releaseImage,
      alt: release.title
    },
    visualAdviceOverview: visualAdviceOverview || [],
    url: `/release/${release.slug}`
  }

  return (
    <div className={classNames(styles.ContainerWrapper)}>
      <div className={styles.Container}>
        <div className={styles.Content}>
          <div className={styles.TopContent}>
            <h2 className={styles.ContentHeading}>About This Film</h2>
          </div>
          <div className={styles.MainContent}>
            <CarouselItem singleItem {...data} small />
          </div>
        </div>
      </div>
    </div>
  )
}

AboutThisFilm.propTypes = {
  release: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    classification: PropTypes.string.isRequired,
    synopsis: PropTypes.string,
    shortFormInsight: PropTypes.string,
    images: PropTypes.shape({
      _751: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    visualAdviceLayer: PropTypes.shape({
      overview: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          strength: PropTypes.number
        })
      ),
      details: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          strength: PropTypes.number
        })
      )
    })
  }).isRequired
}

export default AboutThisFilm
