import React from 'react'
import { string } from 'prop-types'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import ShrinkWrap from '@/component/Primitive/ShrinkWrap'

import styles from './ListItem.module.scss'

const ListItem = ({ category, value }) => {
  const isDark = useDarkContext()
  return (
    <li className={classnames(styles.ListItem, isDark && styles.isDark)}>
      <ShrinkWrap vAlign="middle">
        <ShrinkWrap.Item>
          <div className={styles.Text}>
            <Type as="h4" size="base" className={styles.Category}>
              {category}:
            </Type>
            <Type as="h4" size="base">
              {value}
            </Type>
          </div>
        </ShrinkWrap.Item>
      </ShrinkWrap>
    </li>
  )
}

ListItem.propTypes = {
  category: string,
  value: string
}

export default ListItem
