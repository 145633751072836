import React from 'react'
import styles from './ArticleThumbnailItem.module.scss'
import { string, object } from 'prop-types'

const ArticleThumbnailItem = ({ headline, fullUrlPath, images }) => {
  const imgUrl = images.hero_16x9_1026 && images.hero_16x9_1026[0].url
  const imgAlt = images.hero_16x9_1026 && images.hero_16x9_1026[0].alt
  return (
    <a
      href={fullUrlPath}
      style={{ backgroundImage: imgUrl ? `url(${imgUrl})` : 'none' }}
      className={styles.Container}
    >
      <span role="img" aria-label={imgAlt || headline} />

      <div className={styles.Label}>{headline}</div>
    </a>
  )
}
ArticleThumbnailItem.propTypes = {
  headline: string,
  fullUrlPath: string,
  images: object
}
export default ArticleThumbnailItem
