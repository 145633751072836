import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import Type from '@/component/Primitive/Type'

import styles from './CaptionedMedia.module.scss'

const CaptionedMedia = ({ caption, children, figure, className }) => {
  const isDark = useDarkContext()
  const BodyEl = figure ? 'figure' : 'div'
  const CaptionEl = figure ? 'figcaption' : 'div'

  return (
    <div className={classNames(isDark && styles.isDark, className)}>
      <BodyEl className={styles.CaptionedMediaBody}>{children}</BodyEl>
      {caption && (
        <CaptionEl className={styles.CaptionedMediaCaption}>
          <Type size="base">{caption}</Type>
        </CaptionEl>
      )}
    </div>
  )
}

CaptionedMedia.propTypes = {
  caption: node,
  children: node.isRequired,
  figure: bool,
  className: string
}

export default CaptionedMedia
